import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { t } from "ttag";

import { useNearbyRetailLocations, useUserLocation } from "../../api/locations";
import { LocationID, isoLocationID } from "../../models";
import { LocationList } from "./LocationList";
import { MarkerMap } from "./MarkerMap";
import { UpdateLocationForm } from "./UpdateLocationForm";

import styles from "./index.module.scss";

const GOOGLE_LIBRARIES: Libraries = ["places", "geometry"];

export const LocatorMap = (
    props: React.PropsWithChildren<{
        googleMapsApiKey: string;
    }>,
) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: props.googleMapsApiKey,
        libraries: GOOGLE_LIBRARIES,
    });
    const { userLocation, setEnteredLocation } = useUserLocation();
    const [focusedLocationID, setFocusedLocationID] =
        useState<LocationID | null>(null);
    const [error, setError] = useState("");
    const column1Ref = useRef<HTMLDivElement | null>(null);
    const queryResp = useNearbyRetailLocations(userLocation);
    const locations = queryResp?.locations || [];

    useEffect(() => {
        if (!column1Ref.current || !focusedLocationID) return;
        const item = column1Ref.current.querySelector(
            `[data-id="${isoLocationID.get(focusedLocationID)}"]`,
        );
        if (item instanceof HTMLElement) {
            item.scrollIntoView({ behavior: "smooth" });
        }
    }, [focusedLocationID]);

    if (!isLoaded) return null;

    return (
        <div className={styles.container}>
            <div className={styles.column1} ref={column1Ref}>
                <div className={styles.column1Inner} ref={column1Ref}>
                    <UpdateLocationForm
                        onLocationUpdate={setEnteredLocation}
                        onError={setError}
                    />
                    {error !== "" && (
                        <p className={styles.locationError}>{error}</p>
                    )}
                    {locations.length === 0 && (
                        <p className={styles.locationError}>
                            {t`Enter a valid city, state, or ZIP code.`}
                        </p>
                    )}
                    <LocationList
                        locations={locations}
                        onSelect={setFocusedLocationID}
                    />
                </div>
                {props.children}
            </div>
            <div className={styles.column2}>
                <MarkerMap
                    locations={locations}
                    focusedLocationID={focusedLocationID}
                    setFocusedLocationID={setFocusedLocationID}
                />
            </div>
        </div>
    );
};
