import React, { useContext } from "react";
import { t } from "ttag";

import { Context } from "@reactivated";

import { constants } from "@reactivated/constants";

import { assertNever } from "@thelabnyc/thelabui/src/utils/never";

import {
    Kilometers,
    Meters,
    Miles,
    isoKilometers,
    isoMeters,
    isoMiles,
} from "../../models";

const DistanceUnit = constants["server.cms.types.DistanceUnit"];

type DistanceUnit = (typeof DistanceUnit)[keyof typeof DistanceUnit];

const metersToKMs = (dist: Meters): Kilometers => {
    const unwrappedMeters = isoMeters.unwrap(dist);
    return isoKilometers.wrap(unwrappedMeters / 1000);
};

const metersToMiles = (dist: Meters): Miles => {
    const unwrappedMeters = isoMeters.unwrap(dist);
    return isoMiles.wrap(unwrappedMeters / 1609.344);
};

const formatDist = (dist: Meters, unit: DistanceUnit): string => {
    switch (unit) {
        case DistanceUnit.KM: {
            const distKMs = isoKilometers.unwrap(metersToKMs(dist)).toFixed(1);
            return t`${distKMs} km`;
        }
        case DistanceUnit.MI: {
            const distMIs = isoMiles.unwrap(metersToMiles(dist)).toFixed(1);
            return t`${distMIs} mi`;
        }
        default: {
            return assertNever(unit);
        }
    }
};

const isDistUnit = (unit: string): unit is DistanceUnit => {
    return Object.values(DistanceUnit).includes(unit as DistanceUnit);
};

export const Distance = (props: { distance: Meters }) => {
    const context = useContext(Context);
    const preferredUnit = isDistUnit(context.DISTANCE_UNIT)
        ? context.DISTANCE_UNIT
        : DistanceUnit.KM;
    return <p>{formatDist(props.distance, preferredUnit)}</p>;
};
