import React from "react";

import { ServerCmsModelsPagesMixinsDownloadLibraryTile as Tile } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Clickable } from "../Clickables";

import styles from "./index.module.scss";

export const DownloadLibraryTile = ({ tile }: { tile: Tile }) => (
    <Clickable className={styles.tile} href={tile.url}>
        {tile.image ? (
            <ImageChooserBlock
                value={tile.image}
                attrs={{ className: styles.picture }}
            />
        ) : (
            <div aria-hidden="true" className={styles.picture}></div>
        )}
        <div>
            <p className={styles.sku}>{tile.eyebrow}</p>
            <h3 className={styles.title}>{tile.title}</h3>
            <p className={styles.description}>{tile.description}</p>
        </div>
    </Clickable>
);
