import React from "react";

import { ImageTextSlidesBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { notEmptyOrBlank } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ListCarousel } from "../Carousel/ListCarousel";
import { Clickable } from "../Clickables";

import styles from "./index.module.scss";

type SlideProps = NonNullable<Value["slides"]>[number]["value"];

const Slide = ({ image, url }: SlideProps) => {
    if (!image) return <></>;

    const imageBlock = (
        <ImageChooserBlock
            attrs={{ className: styles.imageContainer }}
            value={image}
            sizes="(max-width: 767px) 64vw, (max-width: 1023px) 28vw, (max-width: 1919px) 15vw, 330px"
            imageAttrs={{
                className: styles.image,
            }}
        />
    );

    const href = url?.url ?? url?.anchor;

    return (
        <div className={styles.slide}>
            {url && href ? (
                <Clickable href={href} className={styles.clickable}>
                    {imageBlock}
                    {url.display_name}
                </Clickable>
            ) : (
                <>{imageBlock}</>
            )}
        </div>
    );
};

const slidesVisibleDesktop = 5;
const slidesVisibleTablet = 3;
const slidesVisibleMobile = 1.5;

interface CustomCss extends React.CSSProperties {
    "--slides-visible-desktop": number;
    "--slides-visible-tablet": number;
    "--slides-visible-mobile": number;
}

export const ImageTextSlides = ({ value: { slides } }: { value: Value }) => {
    if (!slides) return <></>;

    const slideLength = slides.filter(notEmptyOrBlank).length;

    /**
     * If we don't have enough slides to trigger the scroll, we want to center
     * the items, but I couldn't figure out how to do that without changing
     * the CSS.
     */
    let noScrollBreakpoint = "";
    if (slideLength < slidesVisibleDesktop)
        noScrollBreakpoint = styles.noScrollDesktop;
    if (slideLength < slidesVisibleTablet)
        noScrollBreakpoint = styles.noScrollTablet;
    if (slideLength < slidesVisibleMobile)
        noScrollBreakpoint = styles.noScrollMobile;

    return (
        <section
            className={styles.root}
            style={
                {
                    "--slides-visible-desktop": slidesVisibleDesktop,
                    "--slides-visible-tablet": slidesVisibleTablet,
                    "--slides-visible-mobile": slidesVisibleMobile,
                } as CustomCss
            }
        >
            <div className={styles.inner}>
                <ListCarousel
                    label="Media feed"
                    navigationProps={{ className: styles.carouselNav }}
                    wrapperAttrs={{
                        className: concatClassNames([
                            styles.carousel,
                            noScrollBreakpoint,
                        ]),
                    }}
                    listItemAttrs={{
                        className: concatClassNames([
                            styles.listItem,
                            slides.length === 1
                                ? styles.singleListItem
                                : undefined,
                        ]),
                    }}
                    progressClassName={styles.progressBar}
                >
                    {slides.filter(notEmptyOrBlank).map((slide) => (
                        <Slide {...slide.value} key={slide.id} />
                    ))}
                </ListCarousel>
            </div>
        </section>
    );
};
