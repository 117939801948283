import React from "react";

import { CenteredHeroBlock as Value } from "@reactivated";

import { HeroLayoutSettings } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";

import styles from "./index.module.scss";

export interface CenteredHeroProps {
    media: React.JSX.Element | null;
    content: React.JSX.Element | null;
    /**
     * Home page design required a block that was similar to CenteredHero
     * but different in one-off-y ways. Use of isPromo in this component
     * is meant to satisfy the requirements in the most simple and non-
     * invasive ways possible.
     */
    isPromo?: boolean;
    height?: "standard" | "compact" | null;
}

export const CenteredHero = ({
    media,
    content,
    isPromo = false,
    height = "standard",
}: CenteredHeroProps) => {
    const sharedStyles = {
        rootClassName: media === null ? styles.noMedia : styles.hasMedia,
        mediaClassName:
            content !== null && isPromo === false ? styles.overlay : undefined,
        contentClassName: concatClassNames([
            isPromo ? styles.promo : "",
            height === "compact" ? styles.compact : "",
        ]),
    };

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement: "backgroundFull",
        mediaPlacement: "background",
        mediaAspectRatio: height === "standard" ? "stretch" : "none",
        contentAlignPrimary: "middle",
        contentAlignSecondary: "middle",
        textAlignment: "center",
        contentWidth: isPromo ? 8 : 6,
        minHeight: height === "standard" ? 450 : undefined,
    };

    return (
        <HeroSet
            layouts={{
                desktop: { content, media, settings, styles: sharedStyles },
                mobile: {
                    content,
                    media,
                    settings: {
                        ...settings,
                        contentWidth: 12,
                    },
                    styles: sharedStyles,
                },
            }}
        />
    );
};

export const CenteredHeroBlock = ({
    value,
    isPromo = false,
}: {
    value: Value;
    isPromo?: boolean;
}) => {
    return (
        <CenteredHero
            content={value.content ? <RichText value={value.content} /> : null}
            media={
                value.media && value.media.length > 0 ? (
                    <MediaChooserBlock value={value.media} />
                ) : null
            }
            isPromo={isPromo}
            height={value.height}
        />
    );
};
