import React from "react";

import { ServerCmsTypesNavAd } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";

import styles from "./nav-ad.module.scss";

export const NavAd = (props: ServerCmsTypesNavAd) => {
    const inner = (
        <>
            <ImageChooserBlock
                value={props.image}
                attrs={{ className: styles.picture }}
                imageAttrs={{ className: styles.image }}
            />
            <h3 className={styles.title}>{props.title}</h3>
            {props.description && (
                <RichText
                    className={styles.description}
                    value={props.description}
                />
            )}
        </>
    );
    return props.cta.url ? (
        <Clickable href={props.cta.url}>{inner}</Clickable>
    ) : (
        inner
    );
};
