import React from "react";
import { VisuallyHidden } from "react-aria";
import type { TextFieldProps, ValidationResult } from "react-aria-components";
import {
    FieldError,
    Input,
    Label,
    TextField as ReactAriaTextField,
    Text,
    TextArea,
} from "react-aria-components";
import { t } from "ttag";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

interface Props extends TextFieldProps {
    /**
     * For accessibility benefits, there should always be a label, but it
     * doesn't have to be visible to all.
     */
    labelVisible?: boolean;
    label: string;
    labelClassName?: string;
    description?: string;
    placeholder?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    textArea?: boolean;
}

export const TextField = ({
    label,
    labelVisible = true,
    labelClassName,
    description,
    errorMessage,
    textArea = false,
    ...props
}: Props) => {
    return (
        <ReactAriaTextField {...props}>
            <Label
                className={concatClassNames([
                    labelVisible ? styles.label : undefined,
                    labelClassName,
                    props.isRequired ? styles.required : undefined,
                ])}
            >
                {labelVisible ? (
                    label
                ) : (
                    <VisuallyHidden>{label}</VisuallyHidden>
                )}
            </Label>
            {textArea ? (
                <TextArea className={styles.textInput} />
            ) : (
                <Input className={styles.textInput} />
            )}
            {description && (
                <Text className={styles.description} slot="description">
                    {description}
                </Text>
            )}
            <FieldError className={styles.errorMessage}>
                {({ validationDetails, validationErrors }) =>
                    validationDetails.valueMissing
                        ? t`This field is required.`
                        : validationErrors
                }
            </FieldError>
        </ReactAriaTextField>
    );
};
