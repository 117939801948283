import React from "react";
import { t } from "ttag";

import {
    RecipeMetadataEntryBlockList as RecipeMetadataEntryBlockListValue,
    RecipeMetadataEntryBlock as RecipeMetadataEntryBlockValue,
} from "@reactivated";

import styles from "./Metadata.module.scss";

type RecipeMetadataType = Exclude<
    NonNullable<RecipeMetadataEntryBlockValue["type"]>,
    ""
>;

const getTypeLabel = (
    type: RecipeMetadataEntryBlockValue["type"],
): string | null => {
    if (!type) {
        return null;
    }
    const labels: Readonly<{ [T in RecipeMetadataType]: string }> = {
        SERVINGS: t`Servings`,
        PREPPING_TIME: t`Prepping time`,
        COOKING_TIME: t`Cooking time`,
    };
    return labels[type];
};

const RecipeMetadataEntryBlock = (props: {
    value: RecipeMetadataEntryBlockValue;
}) => (
    <div>
        <dt className={styles.metadataType}>
            {getTypeLabel(props.value.type)}
        </dt>
        <dd className={styles.metadataValue}>
            {props.value.value}
            {props.value.note && (
                <p className={styles.note}>{props.value.note}</p>
            )}
        </dd>
    </div>
);

export const RecipeMetadataEntryBlockList = (props: {
    value: RecipeMetadataEntryBlockListValue;
}) => (
    <dl className={styles.metadataList}>
        {props.value.map((entry, i) => (
            <RecipeMetadataEntryBlock key={i} value={entry} />
        ))}
    </dl>
);
