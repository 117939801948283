import React from "react";
import { t } from "ttag";

import {
    RecipeComponentIngredientsBlockList as RecipeComponentIngredientsBlockListValue,
    RecipeComponentIngredientsBlock as RecipeComponentIngredientsBlockValue,
} from "@reactivated";

import { RichText } from "../RichText";

import styles from "./Ingredients.module.scss";

const RecipeComponentIngredientsBlock = (props: {
    value: RecipeComponentIngredientsBlockValue;
}) => (
    <div className={styles.component}>
        {props.value.component_name && (
            <h3 className={styles.componentTitle}>
                {props.value.component_name}
            </h3>
        )}
        {props.value.ingredients && (
            <RichText
                value={props.value.ingredients}
                className={styles.ingredientsText}
            />
        )}
    </div>
);

export const RecipeComponentIngredientsBlockList = (props: {
    value: RecipeComponentIngredientsBlockListValue;
}) =>
    props.value.map((component, i) =>
        i === 0 ? (
            <div key={i} className={styles.firstIngredientGroup}>
                <h2 className={styles.title}>{t`Ingredients`}</h2>
                <RecipeComponentIngredientsBlock value={component} />
            </div>
        ) : (
            <RecipeComponentIngredientsBlock key={i} value={component} />
        ),
    );
