import React from "react";
import { VisuallyHidden } from "react-aria";
import {
    DateFieldProps,
    DateInput,
    DateSegment,
    DateValue,
    FieldError,
    Label,
    DateField as ReactAriaDateField,
    Text,
    ValidationResult,
} from "react-aria-components";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

interface Props<T extends DateValue> extends DateFieldProps<T> {
    /**
     * For accessibility benefits, there should always be a label, but it
     * doesn't have to be visible to all.
     */
    labelVisible?: boolean;
    label: string;
    labelClassName?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
}

export const DateField = <T extends DateValue>({
    label,
    labelVisible = true,
    labelClassName,
    description,
    errorMessage,
    ...props
}: Props<T>) => {
    return (
        <ReactAriaDateField {...props}>
            <Label
                className={concatClassNames([
                    styles.label,
                    labelClassName,
                    props.isRequired ? styles.required : undefined,
                ])}
            >
                {labelVisible ? (
                    label
                ) : (
                    <VisuallyHidden>{label}</VisuallyHidden>
                )}
            </Label>
            <DateInput className={styles.dateInput}>
                {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            {description && <Text slot="description">{description}</Text>}
            <FieldError className={styles.errorMessage}>
                {errorMessage}
            </FieldError>
        </ReactAriaDateField>
    );
};
