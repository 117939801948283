import React from "react";

import { RecipeHeaderBlock as RecipeHeaderBlockValue } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { RichText } from "../RichText";
import { RecipeComponentIngredientsBlockList } from "./Ingredients";
import { RecipeMetadataEntryBlockList } from "./Metadata";

import styles from "./index.module.scss";

export const RecipeHeaderBlock = (props: { value: RecipeHeaderBlockValue }) => (
    <div className={styles.root}>
        <StreamField
            components={{
                metadata: RecipeMetadataEntryBlockList,
                components: RecipeComponentIngredientsBlockList,
                note: (props) => (
                    <RichText {...props} className={styles.note} />
                ),
            }}
            value={props.value}
            attrs={{ className: styles.recipeHeader }}
        />
    </div>
);
