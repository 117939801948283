import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import { Provider, getServerData, getTemplate } from "@reactivated";

import { activateTranslations } from "@thelabnyc/thelabui/src/utils/i18n";

import { FavoritesProvider } from "./favorites";

const { props, context } = getServerData();

if (context.SENTRY_DSN) {
    Sentry.init({
        dsn: context.SENTRY_DSN,
        release: context.CI_COMMIT_SHA,
        environment: context.CI_COMMIT_REF_SLUG,
        integrations: [],
    });
}

activateTranslations(context.i18n);

const main = async () => {
    const Template = await getTemplate(context);

    const root = createRoot(document.getElementById("root")!);
    root.render(
        <React.StrictMode>
            <HelmetProvider>
                <Provider value={context}>
                    <FavoritesProvider>
                        <Template {...props} />
                    </FavoritesProvider>
                </Provider>
            </HelmetProvider>
        </React.StrictMode>,
    );
};

await main();
