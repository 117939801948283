import React, { useContext } from "react";

import {
    Context,
    ServerCmsContextProcessorsNavigationItem as NavigationItem,
} from "@reactivated";

import { FavoritesContext } from "../../favorites";
import { PageNav } from "./PageNav";

/**
 * Thin wrapper to separate context from PageNav, which allows us to work on
 * PageNav in Storybook
 */
export const PageNavWrapper = (props: { nav: NavigationItem[] }) => {
    const context = React.useContext(Context);
    const logoUrl = context.core_assets.logo;
    const favs = useContext(FavoritesContext);

    return <PageNav nav={props.nav} logoUrl={logoUrl} favs={favs} />;
};
