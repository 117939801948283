import React from "react";

import {
    SplitBlockWithValueProps as Value,
    ValuePropSubBlock as ValueProp,
} from "@reactivated";

import { HeroLayoutSettings } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import { RichText } from "../RichText";

import styles from "./index.module.scss";

const ValuePropSubBlock = (props: ValueProp) => (
    <figure className={styles.valueProp}>
        {props.image && <ImageChooserBlock value={props.image} />}
        <figcaption>{props.text}</figcaption>
    </figure>
);

export const SplitBlockWithValueProps = ({ value }: { value: Value }) => {
    const content = value.content ? (
        <div>
            <RichText value={value.content} />
            {value.value_props && value.value_props.length > 0 && (
                <div className={styles.valueProps}>
                    {value.value_props.map((props, index) => (
                        <ValuePropSubBlock
                            {...props}
                            key={`value-prop-${index}`}
                        />
                    ))}
                </div>
            )}
        </div>
    ) : null;

    const media =
        value.media && value.media.length > 0 ? (
            <MediaChooserBlock value={value.media} />
        ) : null;

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement: "container",
        mediaPlacement: "inline",
        mediaAspectRatio: "728:800",
        contentAlignPrimary: "middle",
        contentAlignSecondary:
            value.content_alignment === "left" ? "left/top" : "right/bottom",
        textAlignment: "left",
        contentWidth: 5,
    };

    return (
        <HeroSet
            layouts={{
                desktop: {
                    content,
                    media,
                    settings,
                    styles: {
                        mediaClassName:
                            value.content_alignment === "left"
                                ? styles.padLeft
                                : styles.padRight,
                    },
                },
                mobile: {
                    content,
                    media,
                    settings: {
                        ...settings,
                        heroDirection: "column",
                        contentAlignSecondary: "right/bottom",
                        contentWidth: 12,
                        textAlignment: "center",
                    },
                    styles: {
                        innerClassName: styles.inner,
                    },
                },
            }}
        />
    );
};
