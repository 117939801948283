import React from "react";

import {
    ImageChooserBlock as ImageChooserBlockValue,
    RecipePageBody as RecipePageBodyValue,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { CenteredHeroBlock } from "../CenteredHero";
import { toolboxComponents } from "../PageSkeleton/Toolbox";
import { RecipeAttributionBlock } from "../RecipeAttributionBlock";
import { RecipeHeaderBlock } from "../RecipeHeaderBlock";
import { RecipeInstructionsBlock } from "../RecipeInstructionsBlock";

import styles from "./index.module.scss";

export const RecipePageBody = (props: {
    title: string;
    hero: ImageChooserBlockValue | null;
    body: RecipePageBodyValue;
}) => (
    <article className={styles.recipe}>
        {props.hero && (
            <CenteredHeroBlock
                value={{
                    media: [
                        {
                            type: "image",
                            value: props.hero,
                            id: "recipe-page-body-id",
                        },
                    ],
                    content: null,
                    height: "standard",
                }}
            />
        )}
        <section className={styles.inner}>
            <h1 className={styles.title}>{props.title}</h1>
            <StreamField
                components={{
                    ...toolboxComponents,
                    header: RecipeHeaderBlock,
                    instructions: RecipeInstructionsBlock,
                    attribution: RecipeAttributionBlock,
                }}
                value={props.body}
                tagName={React.Fragment}
            />
        </section>
    </article>
);
