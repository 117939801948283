import React, { useEffect, useRef, useState } from "react";
import { VisuallyHidden } from "react-aria";
import {
    Tab,
    TabList,
    TabListStateContext,
    TabPanel,
    Tabs,
} from "react-aria-components";
import { t } from "ttag";

import {
    ImageChooserBlockListOrNull,
    ImageChooserBlock as ImageChooserBlockValue,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { notEmptyOrBlank } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./Carousel.module.scss";

interface CarouselStyles {
    root?: string;
    wrapper?: string;
    inner?: string;
    nav?: string;
    tabList?: string;
    tab?: string;
    tabPanel?: string;
    navArrow?: string;
}

const Thumbnail = ({ image }: { image: ImageChooserBlockValue }) => {
    return <ImageChooserBlock value={image} sizes="50px" />;
};

export function CarouselNavigationButtons({
    styles: propStyles,
    wrapper,
    list,
    thumbnails,
}: {
    styles?: CarouselStyles;
    wrapper: React.MutableRefObject<HTMLDivElement | null>;
    list: React.MutableRefObject<HTMLDivElement | null>;
    thumbnails: ImageChooserBlockListOrNull;
}) {
    const state = React.useContext(TabListStateContext);

    const [itemWidth, setItemWidth] = useState(10);
    const [allVisible, setAllVisible] = useState(true);
    const [prevScrollBehavior, setPrevScrollBehavior] = useState<
        "last" | "adjacent"
    >("adjacent");
    const [nextScrollBehavior, setNextScrollBehavior] = useState<
        "first" | "adjacent"
    >("adjacent");

    useEffect(() => {
        if (!list.current) return;
        const elementsToWatch = list.current.children;

        /**
         * There are some oddities here because state.selectionManager.setSelectedKeys()
         * needed to have `state` passed into the effect to work properly, but doing
         * that reinitializes the observer. Was just looking for ways to cut down
         * rerenders overall.
         */
        const observer = new IntersectionObserver(
            (entries) => {
                /**
                 * A single entry usually means that an entry has updated, usually
                 * for isIntersecting to toggle. We don't need everything to change
                 * when that happens.
                 */
                const singleEntry = entries.length === 1;

                /**
                 * To distinguish between an updating entry and a collection of one
                 */
                const onlyOneKey = state?.collection.size === 1;

                /** Anything that's visible should be "selected" */
                const visibleEntryIds = entries
                    .filter((entry) => entry.isIntersecting)
                    .map((entry) => {
                        const hackyIdElement = entry.target.querySelector(
                            "[data-hacky-workaround]",
                        );
                        if (!(hackyIdElement instanceof HTMLElement))
                            return null;
                        return hackyIdElement.id;
                    })
                    .filter(notEmptyOrBlank);
                /**
                 * Assumption: at least one slide will always be fully visible.
                 * Happy accident, I think?: the slide going offscreen doesn't
                 * lose focus until the next slide coming on is visible.
                 */
                if (visibleEntryIds.length > 0) {
                    state?.selectionManager.setSelectedKeys(visibleEntryIds);
                }

                /**
                 * If the first item is visible, then clicking the previous
                 * button should take you to the end of the carousel
                 */
                const firstIsVisible =
                    entries.find((entry) => {
                        const hackyIdElement = entry.target.querySelector(
                            "[data-hacky-workaround]",
                        );
                        if (!(hackyIdElement instanceof HTMLElement))
                            return false;

                        return (
                            hackyIdElement.id ===
                            state?.collection.getFirstKey()
                        );
                    })?.isIntersecting || false;
                if (!singleEntry) {
                    setPrevScrollBehavior(firstIsVisible ? "last" : "adjacent");
                }

                /**
                 * If the last item is visible, then clicking the next
                 * button should take you to the beginning of the carousel
                 */
                const lastIsVisible =
                    entries.find((entry) => {
                        const hackyIdElement = entry.target.querySelector(
                            "[data-hacky-workaround]",
                        );
                        if (!(hackyIdElement instanceof HTMLElement))
                            return false;

                        return (
                            hackyIdElement.id === state?.collection.getLastKey()
                        );
                    })?.isIntersecting || false;
                if (!singleEntry) {
                    setNextScrollBehavior(lastIsVisible ? "first" : "adjacent");
                }

                /**
                 * If all items are visible, the button nav is unnecessary
                 */
                const notVisibleCount = entries.filter(
                    (entry) => !entry.isIntersecting,
                ).length;
                /**
                 * If there's only one item, it should be visible, and we
                 * shouldn't need nav anyways
                 */
                if (singleEntry && onlyOneKey) {
                    setAllVisible(true);
                } else if (!singleEntry) {
                    setAllVisible(notVisibleCount === 0);
                }
            },
            { threshold: 0.9, root: wrapper.current },
        );
        [...elementsToWatch].forEach((element) => observer.observe(element));

        return () => {
            [...elementsToWatch].forEach((element) =>
                observer.unobserve(element),
            );
        };
    }, [state]);

    /**
     * This shouldn't be necessary, but Safari on iOS doesn't pay attention to
     * scroll snapping when using scrollBy. This assumes one slide is visible at
     * a time, a different assumption than in ListCarousel.
     */
    useEffect(() => {
        const onResize = () => setItemWidth(wrapper.current?.clientWidth || 10);

        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    /**
     * When clicking previous or next buttons, it should advance by one slide
     * forward or backwards; CSS scroll snapping plus smooth scrolling here
     * is making that look clean.
     *
     * But there are situations where we want to jump to the beginning or end;
     * this uses prevScrollBehavior and nextScrollBehavior, which is set by
     * the intersection observer.
     */
    const scroll = (left: number) => {
        if (!wrapper.current) return;
        const ref = wrapper.current;

        if (nextScrollBehavior === "first" && left > 0) {
            ref.scrollTo({ left: 0, behavior: "smooth" });
        } else if (prevScrollBehavior === "last" && left < 0) {
            ref.scrollTo({ left: ref.scrollWidth, behavior: "smooth" });
        } else {
            ref.scrollBy({ left, behavior: "smooth" });
        }
    };

    /* eslint-disable */
    const keys: string[] = state
        ? // @ts-expect-error: TODO figure out why TS doesn't like keyMap
          Array.from(state.collection.keyMap.keys())
        : [];
    /* eslint-enable */

    const onTabPress = (id: string) => {
        if (!wrapper.current) return;
        const ref = wrapper.current;

        // This assumes the hacky workaround
        let activeSlide = document.getElementById(id);
        if (!activeSlide) return;
        if (activeSlide.parentElement) activeSlide = activeSlide.parentElement;

        const slideLeft = activeSlide.getBoundingClientRect().left;
        const containerLeft = ref.getBoundingClientRect().left;
        const scrollLeft = ref.scrollLeft + (slideLeft - containerLeft);
        ref.scrollTo({ left: scrollLeft, behavior: "smooth" });
    };

    const getTabControls = (id: string) => {
        if (!list.current) return undefined;
        const match = list.current.querySelector(`[id*=tabpanel-${id}]`);
        return match?.id || undefined;
    };

    return (
        <>
            <div
                className={concatClassNames([styles.controls, propStyles?.nav])}
                style={{ display: allVisible ? "none" : undefined }}
            >
                <Clickable
                    aria-label={
                        prevScrollBehavior === "last"
                            ? t`Previous tab`
                            : t`Go to last tab`
                    }
                    onPress={() => scroll(-itemWidth)}
                    className={concatClassNames([
                        styles.navArrow,
                        styles.previous,
                        propStyles?.navArrow,
                    ])}
                >
                    <Svg name="caret-right" />
                </Clickable>
                <Clickable
                    aria-label={
                        nextScrollBehavior === "first"
                            ? t`Next tab`
                            : t`Go to first tab`
                    }
                    onPress={() => scroll(itemWidth)}
                    className={concatClassNames([
                        styles.navArrow,
                        propStyles?.navArrow,
                    ])}
                >
                    <Svg name="caret-right" />
                </Clickable>
            </div>

            {/**
             * Need to remake the tabs because we need to change what happens
             * when you press them.
             */}
            <div
                aria-label={t`Choose slide to display`}
                role="tablist"
                aria-orientation="horizontal"
                className={concatClassNames([
                    styles.tabList,
                    propStyles?.tabList,
                ])}
            >
                {keys.map((key, i0) => {
                    const i = i0 + 1;
                    return (
                        <Clickable
                            key={`key-${key}`}
                            role="tab"
                            id={`tab-${key}`}
                            className={concatClassNames([
                                styles.tab,
                                propStyles?.tab,
                            ])}
                            aria-selected={
                                typeof getTabControls(key) === "string"
                                    ? true
                                    : undefined
                            }
                            aria-controls={getTabControls(key)}
                            onPress={() => onTabPress(key)}
                        >
                            {thumbnails ? (
                                <Thumbnail image={thumbnails[i0]} />
                            ) : (
                                <></>
                            )}
                            <VisuallyHidden>{t`Slide ${i}`}</VisuallyHidden>
                        </Clickable>
                    );
                })}
            </div>
        </>
    );
}

interface CarouselProps {
    /**
     * Should not contain the word "carousel"
     */
    label: string;
    isDisabled?: boolean;
    /**
     * Slide number selected by default
     */
    defaultSelectedKey?: number;
    selectedKey?: number;
    onSelectionChange?: (selectedKey: string | number) => void;
    styles?: CarouselStyles;
    style?: React.CSSProperties;
    thumbnails?: ImageChooserBlockListOrNull;
}

export default function Carousel({
    children,
    label,
    styles: propStyles,
    onSelectionChange,
    thumbnails,
    ...props
}: React.PropsWithChildren<CarouselProps>) {
    const carouselIdRef = useRef(
        `carousel-${Math.random().toString(36).substr(2, 9)}`,
    );
    const wrapper = useRef<HTMLDivElement | null>(null);
    const list = useRef<HTMLDivElement | null>(null);

    const rootProps = {
        ...props,
        className: concatClassNames([styles.root, propStyles?.root]),
    };

    const controls = (
        <CarouselNavigationButtons
            wrapper={wrapper}
            list={list}
            styles={propStyles}
            thumbnails={thumbnails || null}
        />
    );

    /**
     * Seems like panels and tabs need the same ID. We need something unique in
     * here as well because we're doing a querySelector that's looking for this
     */

    const getId = (index: number) =>
        `${carouselIdRef.current}-panel-${String(index)}`;

    return (
        <Tabs {...rootProps} onSelectionChange={onSelectionChange}>
            <div
                role="group"
                aria-roledescription="carousel"
                aria-label={label}
            >
                {controls}
                <div
                    className={concatClassNames([
                        styles.panels,
                        propStyles?.wrapper,
                    ])}
                    ref={wrapper}
                >
                    <div
                        className={concatClassNames([
                            styles.inner,
                            propStyles?.inner,
                        ])}
                        ref={list}
                    >
                        {React.Children.map(children, (child, i) => (
                            <TabPanel
                                shouldForceMount
                                id={getId(i)}
                                className={concatClassNames([
                                    styles.tabPanel,
                                    propStyles?.tabPanel,
                                ])}
                            >
                                <>
                                    <div
                                        aria-hidden="true"
                                        id={getId(i)}
                                        data-hacky-workaround
                                    />
                                    {child}
                                </>
                            </TabPanel>
                        ))}
                    </div>
                </div>
                {/**
                 * Need this here to make the collection keys work correctly;
                 * can't use it because we don't want its default press behavior
                 */}
                <div hidden>
                    <TabList>
                        {React.Children.map(children, (_, i) => (
                            <Tab id={getId(i)} />
                        ))}
                    </TabList>
                </div>
            </div>
        </Tabs>
    );
}
