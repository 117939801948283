import { AbstractSpacerBlock } from "@thelabnyc/thelabui/src/components/SpacerBlock";
import labuiStyles from "@thelabnyc/thelabui/src/components/SpacerBlock/index.module.scss";
import { pApplyProps } from "@thelabnyc/thelabui/src/utils/react";

import sksStyles from "./index.module.scss";

const { spacer, ...extraSpacerStyles } = sksStyles;

export const SpacerBlock = pApplyProps(AbstractSpacerBlock, {
    heightClasses: { ...labuiStyles, ...extraSpacerStyles },
    className: spacer,
});
