import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-aria-components";
import { t } from "ttag";

import {
    ContentTab as IContentTab,
    PageListTab as IPageListTab,
    TabbedBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { ButtonPrimary } from "../Clickables";
import { RichText } from "../RichText";
import { Split } from "../SplitBlock";
import { PageList } from "./PageList";

import styles from "./index.module.scss";

const PageListTab = ({ value, id }: { value: IPageListTab; id?: string }) => (
    <TabPanel className={styles.panel} id={id}>
        <PageList pages={value.pages} />
        {value.cta?.url && value.cta?.display_name && (
            <div className={styles.ctaContainer}>
                <ButtonPrimary href={value.cta.url}>
                    {value.cta.display_name}
                </ButtonPrimary>
            </div>
        )}
    </TabPanel>
);

const ContentTab = ({ value, id }: { value: IContentTab; id?: string }) => (
    <TabPanel className={styles.panel} id={id}>
        <Split
            contentAlignment="left"
            imageWidth="half"
            heroPlacement="full"
            content={value.content ? <RichText value={value.content} /> : <></>}
            media={
                value.image ? <ImageChooserBlock value={value.image} /> : <></>
            }
            contentClassName=""
        />
    </TabPanel>
);

interface Props {
    value: Value;
}

export const TabbedBlock = ({ value: { tabs, title } }: Props) => {
    if (!tabs) return <></>;

    return (
        <div className={styles.root}>
            <Tabs className={styles.tabs}>
                <TabList
                    aria-label={title || t`Tabs`}
                    className={styles.tabList}
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={`${tab.id}-tab`}
                            className={styles.tab}
                            id={tab.id}
                        >
                            {tab.value.tab_label}
                        </Tab>
                    ))}
                </TabList>
                <StreamField
                    tagName={React.Fragment}
                    value={tabs}
                    components={{
                        page_list_tab: PageListTab,
                        content_tab: ContentTab,
                    }}
                />
            </Tabs>
        </div>
    );
};
