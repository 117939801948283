import { toolboxComponents } from "@client/components/PageSkeleton/Toolbox";
import { ProductDetailsBlock } from "@client/components/ProductDetails";
import { RequestQuoteBlock } from "@client/components/RequestQuote";
import React, { useContext } from "react";

import { Context, reverse, templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { ImageBackgroundCarouselBlock } from "../components/ImageBackground";
import { RelatedProductSlides } from "../components/ImageTextSlides/RelatedProducts";
import { PageSkeleton } from "../components/PageSkeleton";
import {
    ScrollNavMenuBlock,
    ScrollNavMenuItemBlock,
} from "../components/ScrollNavMenuBlock";
import { Actions } from "../components/ScrollNavMenuBlock/Actions";

export const Template = (props: templates.ProductPageTemplate) => {
    const context = useContext(Context);
    const url = reverse("cms-api:download-documents");

    return (
        <PageSkeleton {...props.page}>
            <StreamField
                tagName={React.Fragment}
                value={props.page.body.value}
                components={{
                    ...toolboxComponents,
                    image_background_carousel: ({ value }) => (
                        <ImageBackgroundCarouselBlock
                            value={value}
                            extraContent={
                                <Actions
                                    hideAboveTablet={true}
                                    product={{
                                        name: props.page.title,
                                        sku: props.page.product.sku,
                                        url: context.request.url,
                                    }}
                                />
                            }
                        />
                    ),
                    scroll_nav_menu: (args) => (
                        <ScrollNavMenuBlock
                            sku={props.page.product.sku}
                            title={props.page.title}
                            {...args}
                        />
                    ),
                    scroll_nav_menu_item: ScrollNavMenuItemBlock,
                    product_details: ({ value }) => (
                        <ProductDetailsBlock
                            umrp={props.page.msrp}
                            documents={props.page.documents}
                            downloadsUrl={url}
                            sku={props.page.product.sku}
                            title={props.page.title}
                            {...value}
                        />
                    ),
                    related_products: () =>
                        props.page.related_products.length > 0 ? (
                            <RelatedProductSlides
                                products={props.page.related_products}
                            />
                        ) : (
                            <></>
                        ),
                    request_quote: (value) => (
                        <RequestQuoteBlock
                            value={value.value}
                            title={props.page.title}
                            product_image={props.page.downloadlib_tile_image}
                        />
                    ),
                }}
            />
        </PageSkeleton>
    );
};
