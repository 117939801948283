import React from "react";

import { SKSRichTextBlock as Value } from "@reactivated";

import { HeroLayoutSettings } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";

import { RichText } from "../RichText";

const widthToColumn = (width: Value["content_width"]) => {
    switch (width) {
        case "half":
            return 6;
        case "twoThirds":
            return 8;
        case "full":
        default:
            return 12;
    }
};

export const RichTextBlock = ({ value }: { value: Value }) => {
    const content = value.content ? <RichText value={value.content} /> : null;

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement: "container",
        mediaPlacement: "background",
        mediaAspectRatio: "none",
        contentAlignPrimary: "middle",
        contentAlignSecondary: "middle",
        textAlignment: value.text_alignment || "center",
        contentWidth: widthToColumn(value.content_width),
    };

    return (
        <HeroSet
            layouts={{
                desktop: { content, media: null, settings },
                mobile: {
                    content,
                    media: null,
                    settings: {
                        ...settings,
                        contentWidth: 12,
                    },
                },
            }}
        />
    );
};
