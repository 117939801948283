import React from "react";

import { ServerCmsModelsPagesMixinsDownloadLibraryTile as Tile } from "@reactivated";

import { DownloadLibraryTile } from "../DownloadLibraryTile";

import styles from "./index.module.scss";

export const DownloadLibrarySection = (props: {
    heading: string;
    tiles: Tile[];
}) => (
    <section className={styles.section}>
        <h2 className={styles.heading}>{props.heading}</h2>
        <div className={styles.tiles}>
            {props.tiles.map((tile) => (
                <DownloadLibraryTile key={tile.id} tile={tile} />
            ))}
        </div>
    </section>
);
