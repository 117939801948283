import React from "react";

import { ServerCmsTypesSearchResultBadge } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./SearchResultBadge.module.scss";

export const SearchResultBadge = (props: {
    index: number;
    badge: ServerCmsTypesSearchResultBadge;
}) => (
    <span
        className={concatClassNames([
            props.badge.location === "TOP" ? styles.topBadge : "",
            props.badge.location === "BOTTOM"
                ? props.index === 0
                    ? styles.bottomGrayBadge
                    : styles.bottomBadge
                : "",
        ])}
    >
        {props.badge.text}
    </span>
);
