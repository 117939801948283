import React from "react";

import { DualImageSplitBlock as Value } from "@reactivated";

import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";

import styles from "./index.module.scss";

export const DualImageSplitBlock = ({ value }: { value: Value }) => (
    <div
        className={
            value.content_alignment === "right"
                ? styles.flippedRoot
                : styles.root
        }
    >
        <div className={styles.contentColumn}>
            {value.background_media && (
                <MediaChooserBlock
                    value={value.background_media}
                    wrapperAttrs={{ className: styles.media }}
                />
            )}
            {value.content && (
                <div className={styles.contentWrapper}>
                    <RichText
                        value={value.content}
                        className={styles.content}
                    />
                </div>
            )}
        </div>
        <div className={styles.mediaColumn}>
            {value.media && (
                <MediaChooserBlock
                    value={value.media}
                    wrapperAttrs={{ className: styles.media }}
                />
            )}
        </div>
    </div>
);
