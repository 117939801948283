import React, { KeyboardEvent as ReactKeyboardEvent } from "react";
import { FocusScope, useFocusManager } from "react-aria";

import { ServerCmsContextProcessorsNavigationItem } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";
import { NavPopover } from "./NavPopover";
import { NavigationItem } from "./PageNav";

import styles from "./main-menu-item.module.scss";

interface MainMenuItemProps {
    child: NavigationItem;
    nav: ServerCmsContextProcessorsNavigationItem[];
    activeMenu: string | null;
    menuId: string;
    mobileMenuActive: boolean;
    hidden: boolean;
    setActiveMenu: (menuId: string | null) => void;
}

export const MainMenuItem = (props: MainMenuItemProps) => {
    const focusManager = useFocusManager();
    const onKeyDown = (event: ReactKeyboardEvent<HTMLButtonElement>) => {
        if (!focusManager || props.activeMenu !== null) return;
        switch (event.key) {
            case "ArrowRight":
                focusManager.focusNext({ wrap: true });
                break;
            case "ArrowLeft":
                focusManager.focusPrevious({ wrap: true });
                break;
            case "End":
                focusManager.focusLast();
                break;
            case "Home":
                focusManager.focusFirst();
                break;
        }
    };

    return (
        <li
            className={concatClassNames([
                props.mobileMenuActive === true ? styles.l1Li : "",
                props.hidden ? styles.l1liHidden : "",
            ])}
            data-has-children={
                props.child.hasChildren === true ? "true" : undefined
            }
        >
            {props.child.hasChildren ? (
                <>
                    <Clickable
                        className={concatClassNames([
                            props.mobileMenuActive
                                ? styles.l1NavLinkMobile
                                : styles.l1NavLink,
                            !props.hidden && props.activeMenu === props.menuId
                                ? styles.l1NavLinkActive
                                : "",
                        ])}
                        aria-expanded={props.activeMenu === props.menuId}
                        aria-controls={props.menuId}
                        onPress={() => props.setActiveMenu(props.menuId)}
                        onKeyDown={onKeyDown}
                    >
                        {props.child.title}
                        {props.mobileMenuActive && (
                            <Svg
                                className={styles.caretRight}
                                name="caret-right"
                                visuallyHiddenText={null}
                            />
                        )}
                    </Clickable>
                    {props.activeMenu === props.menuId && (
                        <FocusScope contain={false} restoreFocus autoFocus>
                            <NavPopover
                                parent={props.child}
                                menuId={props.menuId}
                                nav={props.nav}
                                mobileDesign={props.mobileMenuActive}
                                setActiveMenu={props.setActiveMenu}
                            />
                        </FocusScope>
                    )}
                </>
            ) : (
                <Clickable
                    href={props.child.url}
                    className={styles.l1NavLink}
                    onKeyDown={onKeyDown}
                >
                    {props.child.title}
                </Clickable>
            )}
        </li>
    );
};
