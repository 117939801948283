import groupBy from "object.groupby";
import React, { useState } from "react";
import { Checkbox, CheckboxGroup, Label } from "react-aria-components";
import { msgid, ngettext, t } from "ttag";

import { ServerCmsModelsSnippetsProductProductDocument as ProductDocumentProps } from "@reactivated";

import { ButtonPrimary, Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./document-list.module.scss";

/** Should be a checkbox but react-aria made that too hard/dumb */
const SelectAllCheckbox = ({
    selectedKeyLength,
    valueLength,
    onPress,
}: {
    selectedKeyLength: number;
    valueLength: number;
    onPress: () => void;
}) => {
    const allSelected = selectedKeyLength === valueLength;
    const someSelected = selectedKeyLength > 0 && !allSelected;

    return valueLength > 1 ? (
        <Clickable
            className={styles.item}
            data-selected={allSelected === true ? true : undefined}
            data-indeterminate={someSelected === true ? true : undefined}
            onPress={onPress}
        >
            <span>{t`All`}</span>
            <Svg
                name={allSelected ? "check" : "indeterminate-dash"}
                className={styles.svg}
                aria-hidden="true"
            />
        </Clickable>
    ) : (
        <></>
    );
};

interface Props {
    value: ProductDocumentProps[];
    downloadsUrl: string;
}

export const DocumentList = ({ value, downloadsUrl }: Props) => {
    const [selected, setSelected] = useState<Record<string, string[]>>({});
    const documents = groupBy(value, (value) => value.doc_type.category.name);
    const getExtension = (url: string) =>
        url.toUpperCase().split(".").pop()?.split(/[?#]/)[0] || "";

    const onChange = (group: Record<string, string[]>) => {
        setSelected((prevSelected) => ({ ...prevSelected, ...group }));
    };

    const handleSelectAll = (key: string, value: ProductDocumentProps[]) => {
        const allSelected = selected[key]?.length === value.length;
        onChange({ [key]: allSelected ? [] : value.map((doc) => `${doc.id}`) });
    };

    const selectedCount = Object.values(selected).reduce(
        (accumulator, current) => accumulator + current.length,
        0,
    );

    const submit = () => {
        /**
         * The IDs we use for `selected` are guaranteed to be unique; file IDs
         * are not, and we want to dedupe them anyways.
         */
        const matchedIds = Object.values(selected)
            .flatMap((id) => id)
            .map((id) => {
                return value.find((doc) => `${doc.id}` === id)!.file.id;
            });
        const dedupedIds = [...new Set(matchedIds)];

        const url = new URL(downloadsUrl, window.location.origin);
        const params = new URLSearchParams();

        dedupedIds.forEach((id) => params.append("document", `${id}`));
        url.search = params.toString();

        window.location.href = url.toString();
    };

    return (
        <form className={styles.form}>
            {Object.entries(documents)
                .sort(
                    ([, a], [, b]) =>
                        a[0].doc_type.category.sort_order -
                        b[0].doc_type.category.sort_order,
                )
                .map(([key, value], dGIndex) => {
                    return (
                        <CheckboxGroup
                            key={`document-group-${dGIndex}`}
                            className={styles.group}
                            value={selected[key]}
                            onChange={(ids) => onChange({ [key]: ids })}
                        >
                            <Label className={styles.groupName}>{key}</Label>
                            <SelectAllCheckbox
                                selectedKeyLength={selected[key]?.length}
                                valueLength={value.length}
                                onPress={() => handleSelectAll(key, value)}
                            />
                            {value
                                .sort(
                                    (a, b) =>
                                        (a.doc_type.sort_order || 0) -
                                        (b.doc_type.sort_order || 0),
                                )
                                .map((document) => (
                                    <Checkbox
                                        key={`document-${document.id}`}
                                        name={`document-${document.id}`}
                                        className={styles.item}
                                        // document.file.id is what we're after
                                        // but there's a risk of duplicate ids
                                        // that is avoidable if we use this:
                                        value={`${document.id}`}
                                    >
                                        <span>
                                            {document.doc_type.name}
                                            <Clickable
                                                href={document.file.url}
                                                className={styles.fileType}
                                                target="_blank"
                                            >
                                                {getExtension(
                                                    document.file.url,
                                                ) === "PDF"
                                                    ? t`Preview`
                                                    : ""}{" "}
                                                {getExtension(
                                                    document.file.url,
                                                )}
                                            </Clickable>
                                        </span>
                                        <Svg
                                            name="check"
                                            className={styles.svg}
                                            aria-hidden="true"
                                        />
                                    </Checkbox>
                                ))}
                        </CheckboxGroup>
                    );
                })}
            <ButtonPrimary
                className={styles.submit}
                aria-live="polite"
                isDisabled={selectedCount === 0}
                type="button"
                onPress={submit}
            >
                {selectedCount === 0
                    ? t`Download Files`
                    : ngettext(
                          msgid`Download ${selectedCount} File`,
                          `Download ${selectedCount} Files`,
                          selectedCount,
                      )}
            </ButtonPrimary>
        </form>
    );
};
