import React from "react";

import {
    ServerCmsModelsPagesMixinsDownloadLibraryTile as Tile,
    templates,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { CenteredHeroWithModal } from "../components/CenteredHero/CenteredHeroWithModal";
import { DownloadLibraryNav } from "../components/DownloadLibraryNav";
import { DownloadLibrarySection } from "../components/DownloadLibrarySection";
import { PageSkeleton } from "../components/PageSkeleton";
import { toolboxComponents } from "../components/PageSkeleton/Toolbox";

const getChildren = (tree: Tile[], parent: Tile): Tile[] => {
    return tree.filter((n) => {
        return n.depth > parent.depth && n.path.startsWith(parent.path);
    });
};

export const Template = (props: templates.DownloadLibraryPageRootTemplate) => {
    const rowDepth = Math.min(...props.categories.map((n) => n.depth));
    const rows = props.categories
        .filter((n) => n.depth === rowDepth)
        .map((row) => ({
            row: row,
            tiles: getChildren(props.categories, row),
        }))
        .filter(({ tiles }) => tiles.length > 0);

    return (
        <PageSkeleton {...props.page}>
            <StreamField
                components={{
                    ...toolboxComponents,
                    centered_hero: CenteredHeroWithModal,
                    download_library: () => (
                        <>
                            <DownloadLibraryNav
                                breadcrumbs={props.breadcrumbs}
                                autocomplete={props.autocomplete}
                            />
                            {rows.map(({ row, tiles }) => (
                                <DownloadLibrarySection
                                    key={row.id}
                                    heading={row.title}
                                    tiles={tiles}
                                />
                            ))}
                        </>
                    ),
                }}
                value={props.page.body.value}
                tagName={React.Fragment}
            />
        </PageSkeleton>
    );
};
