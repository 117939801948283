import { reverse } from "@reactivated";

import { CSRF_HEADER, loadCSRFToken } from "@thelabnyc/thelabui/src/utils/api";

import { RegisteredBuilder, check } from "../models";

export const allocateBuilderID = async (
    companyName: string,
): Promise<RegisteredBuilder> => {
    const resp = await fetch(reverse("cms-api:registered-builders"), {
        method: "POST",
        headers: {
            [CSRF_HEADER]: await loadCSRFToken(),
        },
        body: new URLSearchParams({
            company_name: companyName,
        }),
    });
    const data = (await resp.json()) as unknown;
    return check(RegisteredBuilder.decode(data));
};
