import React from "react";

import { ServerCmsTypesRelatedProduct } from "@reactivated";

import { ImageTextSlides } from ".";

interface Props {
    products: ServerCmsTypesRelatedProduct[];
}

export const RelatedProductSlides = ({ products }: Props) => (
    <ImageTextSlides
        value={{
            slides: products.map((product, index) => ({
                type: "slide",
                value: {
                    image: product.image,
                    url: {
                        display_name: product.title,
                        link_type: "page",
                        anchor: "",
                        url: product.url,
                    },
                },
                id: `related-product-${index}`,
            })),
        }}
    />
);
