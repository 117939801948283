import React, { useContext, useEffect, useRef, useState } from "react";
import { t } from "ttag";

import { Context } from "@reactivated";

import {
    NavMenuRefsProvider,
    hooks,
} from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonPrimary, ButtonSecondary } from "../Clickables";
import { Svg } from "../Svg";
import { Actions } from "./Actions";
import { MenuToggle } from "./MenuToggle";

import styles from "./ScrollNavMenuContainer.module.scss";

export const ScrollNavMenuContainer = (
    props: React.PropsWithChildren<{
        sku: string;
        title: string;
    }>,
) => {
    const context = useContext(Context);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const fullBleedRef = useRef<HTMLDivElement | null>(null);
    const navLinksRef = useRef<HTMLUListElement | null>(null);

    const onCloseMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        hooks.onNavItemClicked.on(onCloseMenu);
        return () => {
            hooks.onNavItemClicked.off(onCloseMenu);
        };
    }, []);

    return (
        <NavMenuRefsProvider value={{ fullBleedRef, navLinksRef }}>
            <div
                data-id="scroll-nav-menu"
                ref={fullBleedRef}
                className={styles.navMenuFullBleed}
            >
                <div className={styles.productContainer}>
                    <div className={styles.productContainerInner}>
                        <div className={styles.titleWrapper}>
                            <h1 className={styles.title}>{props.title}</h1>
                            <div className={styles.titleInfo}>
                                <p className={styles.productTag}>{props.sku}</p>
                                <Actions
                                    product={{
                                        name: props.title,
                                        sku: props.sku,
                                        url: context.request.url,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.ctas}>
                            <ButtonSecondary
                                href={context.wagtail_links.find_dealer}
                            >
                                <span className={styles.ctaTextDesktop}>
                                    {t`Find a Dealer`}
                                </span>
                                <span className={styles.ctaTextMobile}>
                                    {t`Dealer`}
                                </span>
                                <Svg name="caret-right" />
                            </ButtonSecondary>
                            <ButtonPrimary href="#request-a-quote">
                                <span className={styles.ctaTextDesktop}>
                                    {t`Request a Quote`}
                                </span>
                                <span className={styles.ctaTextMobile}>
                                    {t`Quote`}
                                </span>
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>
                <div className={styles.navStatus}>
                    <MenuToggle
                        isOpen={isMenuOpen}
                        onToggle={() => {
                            setIsMenuOpen((open) => !open);
                        }}
                    />
                </div>
                <nav
                    className={classNames([
                        [styles.navItems, true],
                        [styles.navIsOpen, isMenuOpen],
                    ])}
                >
                    <ul ref={navLinksRef} className={styles.navItemList}>
                        {props.children}
                    </ul>
                </nav>
            </div>
        </NavMenuRefsProvider>
    );
};
