import React, { Fragment } from "react";

import { ServerCmsTypesPageAd } from "@reactivated";

import { PageAdLayout } from "../PageAdLayout";
import { SpacerBlock } from "../SpacerBlock";

interface Props {
    pageChildren: ServerCmsTypesPageAd[];
}

export const PageChildren = ({ pageChildren }: Props) => {
    return pageChildren.map((child, index) => (
        <Fragment key={child.cta.url}>
            <SpacerBlock
                value={{ height: index === 0 ? "xxxlarge" : "xlarge" }}
            />
            <PageAdLayout value={child} />
            {index + 1 === pageChildren.length && (
                <SpacerBlock value={{ height: "xxxlarge" }} />
            )}
        </Fragment>
    ));
};
