import React from "react";

import { templates } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { CenteredHero } from "../CenteredHero";
import { IndexItem } from "./IndexItem";

import styles from "./index.module.scss";

export const PressReleaseIndex = (props: {
    page: templates.PressReleaseIndexPageTemplate["page"];
}) => (
    <>
        <CenteredHero
            content={<h1 className={styles.title}>{props.page.title}</h1>}
            media={
                props.page.head_image ? (
                    <ImageChooserBlock value={props.page.head_image.block} />
                ) : null
            }
        />
        <div>
            <div className={styles.inner}>
                <div className={styles.articles}>
                    {props.page.articles.map((article) => (
                        <IndexItem key={article.id} article={article} />
                    ))}
                </div>
            </div>
        </div>
    </>
);
