import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-aria-components";
import { t } from "ttag";

import {
    Money,
    ServerCmsModelsSnippetsProductProductDocument as ProductDocumentProps,
    ProductDetailBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import Carousel from "../Carousel";
import { DocumentList } from "./DocumentList";
import { SpecificationDetails } from "./SpecificationDetails";

import styles from "./index.module.scss";

interface Props extends Value {
    documents: ProductDocumentProps[];
    umrp: Money | null;
    downloadsUrl: string;
    title: string;
    sku: string;
}

export const ProductDetailsBlock = ({
    documents,
    umrp,
    downloadsUrl,
    specification_details,
    images,
    title,
    sku,
}: Props) => {
    return (
        <section>
            <div className={styles.inner}>
                <div className={styles.media}>
                    {images?.length === 1 && (
                        <ImageChooserBlock
                            value={images[0]}
                            attrs={{ className: styles.pictureSingleImage }}
                            imageAttrs={{ className: styles.image }}
                        />
                    )}
                    {images?.length && images.length > 1 && (
                        <Carousel
                            label={t`Product Images`}
                            styles={{
                                root: styles.carousel,
                                nav: styles.carouselNav,
                                navArrow: styles.navArrow,
                                wrapper: styles.wrapper,
                                tabList: styles.carouselTabList,
                                tabPanel: styles.carouselPanel,
                                tab: styles.carouselTab,
                            }}
                            thumbnails={images}
                        >
                            {images?.map((image, index) => (
                                <ImageChooserBlock
                                    key={`${image.url}-${index}`}
                                    value={image}
                                    attrs={{ className: styles.picture }}
                                    imageAttrs={{ className: styles.image }}
                                />
                            ))}
                        </Carousel>
                    )}
                    <p className={styles.caption}>
                        {sku}
                        <br />
                        {title}
                    </p>
                </div>

                <div className={styles.content}>
                    <Tabs>
                        <TabList
                            aria-label={t`Product Details`}
                            className={styles.tabList}
                        >
                            <Tab className={styles.tab} id="specs">
                                {t`Specification Details`}
                            </Tab>
                            <Tab className={styles.tab} id="docs">
                                {t`Documents`}
                            </Tab>
                        </TabList>
                        <TabPanel id="specs">
                            <SpecificationDetails
                                umrp={umrp}
                                value={specification_details}
                            />
                        </TabPanel>
                        <TabPanel className={styles.documentPanel} id="docs">
                            <DocumentList
                                value={documents}
                                downloadsUrl={downloadsUrl}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </section>
    );
};
