import React, { Fragment, useEffect, useRef, useState } from "react";
import { FocusScope, VisuallyHidden, usePreventScroll } from "react-aria";
import {
    Dialog,
    DialogTrigger,
    OverlayArrow,
    Popover,
} from "react-aria-components";
import { t } from "ttag";

import { ServerCmsContextProcessorsNavigationItem } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { FavoritesContext } from "../../favorites";
import { Clickable } from "../Clickables";
import { SearchForm } from "../SearchForm";
import { Svg } from "../Svg";
import { FavoritesList } from "./FavoritesList";
import { MainMenuItem } from "./MainMenuItem";
import { getChildren } from "./utils";

import styles from "./index.module.scss";

export interface NavigationItem
    extends ServerCmsContextProcessorsNavigationItem {
    hasChildren: boolean;
}

export interface Props {
    logoUrl: string;
    nav: ServerCmsContextProcessorsNavigationItem[];
    favs: React.ContextType<typeof FavoritesContext>;
}

export const PageNav = (props: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const spacerRef = useRef<HTMLLIElement | null>(null);
    const logoRef = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null);
    /**
     * Used to determine which main menu item is "open" and therefore should show
     * its contents. The string is the menu id.
     */
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    /**
     * There's a desktop and mobile design like usual, but the visible one isn't
     * determined by mobile breakpoint, it's determined by whether or not the
     * main menu items are overlapping the logo.
     */
    const [mobileLayout, setMobileLayout] = useState<boolean | null>(null);
    /**
     * On a basic level this exists so the burger button can trigger/hide the
     * main menu in the mobileLayout, but we're also using it to trigger all
     * of the style changes that come along with it.
     */
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const activeMenuChange = (id: string | null) =>
        setActiveMenu(id === activeMenu ? null : id);

    const keyHandler = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            if (activeMenu !== null) setActiveMenu(null);
            if (mobileMenuActive) setMobileMenuActive(false);
        }
    };

    const focusOutHandler = (event: globalThis.FocusEvent) => {
        if (
            ref.current &&
            event.relatedTarget instanceof Node &&
            !ref.current.contains(event.relatedTarget)
        )
            setActiveMenu(null);
    };

    const overlapCheck = () => {
        if (!spacerRef.current || !logoRef.current) return;
        setMobileLayout(null);
        setMobileMenuActive(false);
        setActiveMenu(null);
        setTimeout(() => {
            if (!spacerRef.current || !logoRef.current) return;
            const spacerRect = spacerRef.current.getBoundingClientRect();
            const logoRect = logoRef.current.getBoundingClientRect();
            const padding = 0;
            const overlapping =
                spacerRect.left > logoRect.left - padding ||
                spacerRect.right < logoRect.right + padding;
            setMobileLayout(overlapping);
        }, 700);
    };

    useEffect(() => {
        if (typeof document !== "undefined") {
            document.addEventListener("keydown", keyHandler);
        }

        return () => {
            if (typeof document !== "undefined") {
                document.removeEventListener("keydown", keyHandler);
            }
        };
    }, [activeMenu, mobileMenuActive]);

    useEffect(() => {
        ref.current?.addEventListener("focusout", focusOutHandler);
        overlapCheck();
        if (typeof window !== "undefined") {
            window.addEventListener("resize", overlapCheck);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("focusout", focusOutHandler);
            }
            if (typeof window !== "undefined") {
                window.addEventListener("resize", overlapCheck);
            }
        };
    }, []);

    usePreventScroll({ isDisabled: !mobileMenuActive });

    const root = props.nav.find((item) => item.depth === 0);
    if (!root) return <></>;

    const l1 = getChildren(root, props.nav);

    /**
     * The mobile design wants to have a divider between a group of main menu
     * items with NavPopovers and a group of items without. This does the sort.
     */
    const l1Sorted = l1
        .sort((a, b) => {
            const aHasChildren = +(getChildren(a, props.nav).length > 0);
            const bHasChildren = +(getChildren(b, props.nav).length > 0);
            return bHasChildren - aHasChildren;
        })
        .map((item) => ({
            ...item,
            hasChildren: getChildren(item, props.nav).length > 0,
        }));

    const label = "Main Site Navigation";

    /**
     * mobileLayout scheme requires resetting to the desktop layout to check
     * for overlapping; it's best to have things hidden while this happens.
     * Doing it with CSS for the smooth transition.
     */
    const layoutSetClass =
        mobileLayout === null ? styles.layoutUnset : styles.layoutSet;

    return (
        <nav
            className={concatClassNames([
                styles.nav,
                activeMenu || mobileMenuActive ? styles.navOpen : "",
            ])}
            aria-label={label}
            ref={ref}
        >
            <Clickable
                href={root.url}
                className={styles.homePage}
                ref={logoRef}
            >
                <img src={props.logoUrl} />
                <VisuallyHidden>{root.title}</VisuallyHidden>
            </Clickable>

            <FocusScope contain={mobileMenuActive}>
                <div
                    className={concatClassNames([
                        styles.mobileMenuControl,
                        layoutSetClass,
                        mobileLayout === false
                            ? styles.hideMobileMenuControl
                            : "",
                    ])}
                >
                    <Clickable
                        className={styles.mobileMenuClickable}
                        onPress={() => setMobileMenuActive(!mobileMenuActive)}
                    >
                        {mobileMenuActive ? (
                            <Svg
                                name="x"
                                visuallyHiddenText={`Close ${label}`}
                            />
                        ) : (
                            <Svg
                                name="burger"
                                visuallyHiddenText={`Open ${label}`}
                            />
                        )}
                    </Clickable>
                </div>
                <ul
                    className={concatClassNames([
                        mobileMenuActive === true
                            ? styles.l1NavMobile
                            : styles.l1Nav,
                        layoutSetClass,
                        mobileLayout === true && mobileMenuActive === false
                            ? styles.hideL1Nav
                            : "",
                    ])}
                >
                    {l1Sorted.map((l1child, index) => {
                        const menuId = `menu-${l1child.path}`;
                        return (
                            <Fragment key={l1child.path}>
                                {index === Math.ceil(l1.length / 2) &&
                                    !mobileMenuActive && (
                                        <li
                                            aria-hidden="true"
                                            className={styles.spacer}
                                            ref={spacerRef}
                                        ></li>
                                    )}
                                <MainMenuItem
                                    child={l1child}
                                    nav={props.nav}
                                    activeMenu={activeMenu}
                                    setActiveMenu={activeMenuChange}
                                    menuId={menuId}
                                    mobileMenuActive={mobileMenuActive}
                                    hidden={
                                        mobileMenuActive &&
                                        activeMenu !== null &&
                                        activeMenu !== menuId
                                    }
                                />
                            </Fragment>
                        );
                    })}
                </ul>
                <div
                    className={concatClassNames([
                        styles.extras,
                        layoutSetClass,
                    ])}
                >
                    <DialogTrigger>
                        <Clickable className={styles.extraClickable}>
                            <Svg name="search" visuallyHiddenText={t`Search`} />
                        </Clickable>
                        <Popover className={styles.popover} placement="bottom">
                            <OverlayArrow className={styles.overlayArrow}>
                                <svg width={12} height={12} viewBox="0 0 12 12">
                                    <path d="M0 0 L6 6 L12 0" />
                                </svg>
                            </OverlayArrow>
                            <Dialog className={styles.searchDialog}>
                                <SearchForm className={styles.searchForm} />
                            </Dialog>
                        </Popover>
                    </DialogTrigger>
                    <DialogTrigger>
                        <Clickable
                            className={
                                props.favs.items.length > 0
                                    ? styles.extraClickableFilled
                                    : styles.extraClickable
                            }
                        >
                            <Svg
                                name={
                                    props.favs.items.length > 0
                                        ? "heart-filled"
                                        : "heart"
                                }
                                visuallyHiddenText={t`Favorites`}
                            />
                        </Clickable>
                        <Popover className={styles.popover} placement="bottom">
                            <OverlayArrow className={styles.overlayArrow}>
                                <svg width={12} height={12} viewBox="0 0 12 12">
                                    <path d="M0 0 L6 6 L12 0" />
                                </svg>
                            </OverlayArrow>
                            <Dialog className={styles.favoritesDialog}>
                                <FavoritesList
                                    favs={props.favs}
                                    className={styles.favoritesList}
                                />
                            </Dialog>
                        </Popover>
                    </DialogTrigger>
                </div>
            </FocusScope>
        </nav>
    );
};
