import React, { useContext } from "react";
import { t } from "ttag";

import { Context } from "@reactivated";

import { Select, SelectItem } from "../Forms/Select";

export const StateSelect = ({ className }: { className?: string }) => {
    const context = useContext(Context);

    return (
        <Select
            label={t`State`}
            name="state"
            isRequired
            placeholder={t`Select a state`}
            className={className}
        >
            {Object.entries(context.STATES).map(([code, name]) => (
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
                <SelectItem key={code} id={code} value={code as any}>
                    {name}
                </SelectItem>
            ))}
        </Select>
    );
};
