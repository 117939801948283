import React from "react";
import { t } from "ttag";

import { CRMForm, FormType, WhoAreYouSelect } from "../CRMForm";
import { StateSelect } from "../CRMForm/StateSelect";
import { ButtonPrimary } from "../Clickables";
import { DateField } from "../Forms/Date";
import { TextField } from "../Forms/Input";

import styles from "./forms.module.scss";

export const ConnectWithDealerForm = () => (
    <CRMForm layout="solo" type={FormType.CONNECT_WITH_DEALER}>
        {(disabled, legal) => (
            <>
                <WhoAreYouSelect className={styles.input} />
                <TextField
                    label={t`First Name`}
                    className={styles.input}
                    name="first_name"
                    isRequired
                />
                <TextField
                    label={t`Last Name`}
                    className={styles.input}
                    name="last_name"
                    isRequired
                />
                <TextField
                    label={t`Email Address`}
                    className={styles.input}
                    name="email_address"
                    isRequired
                    type="email"
                />
                <TextField
                    label={t`Phone Number`}
                    className={styles.input}
                    name="phone"
                />

                <TextField
                    label={t`City`}
                    className={styles.input}
                    name="city"
                    isRequired
                />
                <StateSelect className={styles.input} />
                <TextField
                    label={t`ZIP Code`}
                    className={styles.input}
                    name="zip_code"
                    isRequired
                />
                <DateField
                    name="estimated_project_date"
                    label={t`Estimated Project Date`}
                    isRequired
                />
                <TextField
                    label={t`If you are working with a dealer, please specify the dealer name`}
                    className={styles.input}
                    name="dealer_name"
                    isRequired
                />
                <TextField
                    label={t`Message`}
                    className={styles.input}
                    name="message"
                    textArea={true}
                />
                <input
                    type="hidden"
                    name="reason_for_this_email"
                    value="Request a Quote"
                />
                {legal}
                <div className={styles.submitContainer}>
                    <ButtonPrimary disabled={disabled} type="submit">
                        {t`Connect With a Dealer`}
                    </ButtonPrimary>
                </div>
            </>
        )}
    </CRMForm>
);
