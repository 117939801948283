import React from "react";
import { Dialog, DialogTrigger, Modal } from "react-aria-components";
import { t } from "ttag";

import {
    ModalVideoHeroBlock as Value,
    VideoChooserBlockOrNull,
} from "@reactivated";

import { HeroLayoutSettings } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

import { ButtonPrimary, Clickable } from "../Clickables";
import { RichText } from "../RichText";
import { VideoPlayerControls } from "../VideoPlayerControls";
import { CloseButton } from "./CloseButton";

import styles from "./index.module.scss";

const Content = ({
    richText,
    ctaText,
    modalVideo,
}: {
    richText: string | null;
    ctaText?: string | null;
    modalVideo: VideoChooserBlockOrNull;
}) => (
    <div>
        {richText && <RichText value={richText} />}
        <DialogTrigger>
            <ButtonPrimary className={styles.cta}>
                {ctaText && ctaText !== "" ? ctaText : t`Play`}
            </ButtonPrimary>
            <Modal className={styles.modal} isDismissable>
                <Dialog className={styles.dialog}>
                    {({ close }) => (
                        <>
                            <Clickable
                                className={styles.overlay}
                                onPress={close}
                                aria-hidden="true"
                                tabIndex={-1}
                            />
                            <CloseButton
                                closeText={t`Close Video`}
                                onPress={close}
                            />
                            {modalVideo && (
                                <VideoChooserBlock
                                    value={modalVideo}
                                    attrs={{
                                        className: styles.modalVideo,
                                        autoPlay: true,
                                    }}
                                />
                            )}
                        </>
                    )}
                </Dialog>
            </Modal>
        </DialogTrigger>
    </div>
);

export const ModalVideoHeroBlock = ({ value }: { value: Value }) => {
    const content = (
        <Content
            richText={value.content}
            ctaText={value.cta_text}
            modalVideo={value.modal_video}
        />
    );
    const media = value.background_video ? (
        <VideoPlayerControls value={value.background_video} />
    ) : null;

    const sharedStyles = {
        rootClassName: styles.root,
        mediaClassName: styles.media,
    };

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement: "backgroundFull",
        mediaPlacement: "background",
        mediaAspectRatio: "16:9",
        contentAlignPrimary: "middle",
        contentAlignSecondary: "middle",
        textAlignment: "center",
        contentWidth: 6,
        maxHeight: 800,
    };

    return (
        <HeroSet
            layouts={{
                desktop: {
                    content,
                    media,
                    settings,
                    styles: sharedStyles,
                },
                mobile: {
                    content,
                    media,
                    settings: {
                        ...settings,
                        contentWidth: 12,
                        minHeight: 667,
                        mediaAspectRatio: "stretch",
                    },
                    styles: sharedStyles,
                },
            }}
        />
    );
};
