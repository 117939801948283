import React from "react";
import { t } from "ttag";

import { Select, SelectItem } from "../Forms/Select";

const reasonForThisEmail = [
    ["Support & Operation", t`Support & Operation`],
    ["Dealer Inquiries", t`Dealer Inquiries`],
    ["Request a Quote", t`Request a Quote`],
    ["Installation", t`Installation`],
    ["Manuals and Documentation", t`Manuals and Documentation`],
    ["Repair Service", t`Repair Service`],
    ["Press Inquiries", t`Press Inquiries`],
    ["Website Issue", t`Website Issue`],
] as const satisfies [string, string][];

export const ReasonForThisEmail = ({ className }: { className?: string }) => (
    <Select
        label={t`Reason For This Email`}
        name="reason_for_this_email"
        isRequired
        placeholder={t`Select an item`}
        className={className}
    >
        {reasonForThisEmail.map(([value, label]) => (
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */
            <SelectItem key={value} id={value} value={value as any}>
                {label}
            </SelectItem>
        ))}
    </Select>
);
