import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

export const LogoLoader = ({ className }: { className?: string }) => (
    <div
        className={concatClassNames([styles.root, className])}
        aria-hidden="true"
    >
        <svg viewBox="0 0 394 250" className={styles.outline}>
            <path d="M 197.18359,10.186273 0,41.664621 V 202.05978 L 197.18359,239.1581 394,202.35757 V 41.439615 Z m -0.002,18.552963 185.63762,22.769451 V 192.39417 L 197.18359,220.90881 11.55052,192.51256 V 51.325521 Z" />
        </svg>
        <div className={styles.gradient}></div>
        <svg viewBox="0 0 394 250" className={styles.s}>
            <path d="m 235.41855,87.272035 c 8.08881,0 17.81404,0.0052 17.81404,0.0052 0,-29.831488 -29.932,-36.456387 -53.73064,-36.993784 -53.63151,0 -66.42983,24.319262 -66.42983,38.139512 0,25.228447 21.34503,37.295317 36.23678,43.699387 12.76159,5.753 55.14031,17.05093 55.14031,31.80626 0,12.26909 -15.17564,19.3513 -34.38419,19.39354 -19.20854,0.0423 -40.57316,-8.0021 -40.57316,-22.64965 -7.42059,0 -11.77807,0 -17.44375,0 0,16.36303 7.99214,38.94862 61.95844,38.94862 23.85403,0 67.79753,-10.63755 67.79753,-39.56451 0,-32.18922 -48.52371,-48.2874 -48.52371,-48.2874 0,0 -29.29081,-12.73947 -34.03328,-15.344602 -4.74247,-2.605127 -8.96023,-7.163857 -8.96023,-13.023473 0,-9.281414 9.5669,-15.550218 30.17056,-15.550218 24.56766,0 34.96113,11.115144 34.96113,19.421147 z" />
        </svg>
        <div className={styles.backdrop}></div>
    </div>
);
