import React from "react";

import { ServerCmsTypesSearchResultBadge } from "@reactivated";

import { SearchResultBadge } from "./SearchResultBadge";

import styles from "./SearchResultBadges.module.scss";

export const SearchResultBadges = (props: {
    badges: ServerCmsTypesSearchResultBadge[];
}) => (
    <div className={styles.badges}>
        {props.badges.map((badge, i) => (
            <SearchResultBadge key={i} index={i} badge={badge} />
        ))}
    </div>
);
