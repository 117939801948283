import React from "react";
import { t } from "ttag";

import { CharBlockOrNull } from "@reactivated";

import { CRMForm, FormType, WhoAreYouSelect } from "../CRMForm";
import { StateSelect } from "../CRMForm/StateSelect";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { RichText } from "../RichText";
import { FormLayout } from "./CRMForm";

import styles from "./forms.module.scss";

interface Props extends FormLayout {
    legalText?: CharBlockOrNull;
}

export const StayInformedForm = (props: Props) => {
    return (
        <CRMForm layout={props.layout} type={FormType.STAY_INFORMED}>
            {(disabled, legal) => (
                <>
                    <WhoAreYouSelect className={styles.input} />
                    <TextField
                        label={t`City`}
                        className={styles.input}
                        name="city"
                        isRequired
                    />
                    <StateSelect className={styles.input} />
                    <TextField
                        label={t`ZIP Code`}
                        className={styles.input}
                        name="zip_code"
                        isRequired
                    />
                    <TextField
                        label={t`Email Address`}
                        className={styles.input}
                        name="email_address"
                        isRequired
                        type="email"
                    />
                    {props.legalText ? (
                        <RichText
                            className={styles.legal}
                            value={props.legalText}
                        />
                    ) : (
                        legal
                    )}
                    <div className={styles.submitContainer}>
                        <ButtonPrimary type="submit" disabled={disabled}>
                            {t`Keep Me Informed`}
                        </ButtonPrimary>
                    </div>
                </>
            )}
        </CRMForm>
    );
};
