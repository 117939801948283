import React from "react";

import { Svg } from "../Svg";

import styles from "./LocationListItem.module.scss";

export const getGlyph = (index: number) => {
    return `${index + 1}`;
};

export const IndexGlyph = (props: { index: number }) => {
    return (
        <p className={styles.glyph}>
            <Svg name="map-pin" className={styles.pin} aria-hidden="true" />
            {getGlyph(props.index)}
        </p>
    );
};
