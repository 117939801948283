import React from "react";

import { RichTextBlock as BaseRichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./rich-text.module.scss";

type TerminalMargin = "both" | "none" | "firstChild" | "lastChild";

export interface Props extends React.ComponentProps<typeof BaseRichTextBlock> {
    /**
     * Usually we want the margin-top of the rich text's first child and the
     * margin-bottom of the rich text's last child to be trimmed so they don't
     * affect the layout. But there are some cases where it's useful, so here
     * are a few ways out if you need them.
     */
    trimTerminalMargins?: TerminalMargin;
    applyLengthBasedStyles?: boolean;
}

export function RichText({
    trimTerminalMargins = "both",
    applyLengthBasedStyles = false,
    ...props
}: Props) {
    const characterCountThreshold = 500;
    const lengthBasedStyle =
        applyLengthBasedStyles && props.value.length < characterCountThreshold
            ? styles.conciseContent
            : styles.lengthyContent;

    return (
        <BaseRichTextBlock
            {...props}
            className={concatClassNames([
                styles.richText,
                styles[trimTerminalMargins],
                lengthBasedStyle,
                props.className,
            ])}
        />
    );
}
