import React from "react";

import { Phone } from "../../models";
import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./LocationListItem.module.scss";

export const PhoneNumber = (props: { phone: Phone }) => (
    <Clickable href={`tel:${props.phone.e164}`} className={styles.itemWithIcon}>
        <Svg name="phone" aria-hidden="true" className={styles.svg} />
        <span className={styles.textLink}>{props.phone.national}</span>
    </Clickable>
);
