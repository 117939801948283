import React from "react";

import { FooterNavigationBlock as FooterNavigationBlockValue } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { FooterColumns } from "./FooterColumns";
import { FooterLegal } from "./FooterLegal";
import { FooterSocialMedia } from "./FooterSocialMedia";

import styles from "./index.module.scss";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    value: FooterNavigationBlockValue;
}

export const FooterNavigation = React.forwardRef<HTMLDivElement, IProps>(
    ({ value: propsValue, ...props }, ref) => (
        <footer
            {...props}
            ref={ref}
            className={concatClassNames([styles.root, props.className])}
        >
            <div className={styles.mainContentContainer}>
                <div className={styles.inner}>
                    <StreamField
                        components={{
                            columns: FooterColumns,
                            social_media: FooterSocialMedia,
                            legal: FooterLegal,
                        }}
                        value={propsValue}
                        tagName={React.Fragment}
                    />
                </div>
            </div>
        </footer>
    ),
);
