import React, { Fragment } from "react";

import { FooterLinksColumnList } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";

import styles from "./FooterColumn.module.scss";

export const FooterColumns = (props: { value: FooterLinksColumnList }) => (
    <div className={styles.columnsContainer}>
        {props.value.map((column, i) => (
            <dl
                key={i}
                className={concatClassNames([
                    styles.dl,
                    column.width ? styles[column.width] : "",
                ])}
            >
                {column.title && (
                    <RichText
                        className={styles.columnTitle}
                        value={column.title}
                        tagName={"dt"}
                    />
                )}
                {column.links?.map((link, j) =>
                    link.url ? (
                        <dd key={j}>
                            <Clickable href={link.url} className={styles.link}>
                                {link.display_name}
                            </Clickable>
                        </dd>
                    ) : (
                        <Fragment key={j}></Fragment>
                    ),
                )}
            </dl>
        ))}
    </div>
);
