import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { PressReleaseIndex } from "../components/PressReleaseIndex";

export const Template = (props: templates.PressReleaseIndexPageTemplate) => (
    <PageSkeleton {...props.page}>
        <main>
            <PressReleaseIndex page={props.page} />
        </main>
    </PageSkeleton>
);
