import React from "react";

import { LocationID, LocationProximity, isoLocationID } from "../../models";
import { LocationListItem } from "./LocationListItem";

import styles from "./LocationList.module.scss";

export const LocationList = (props: {
    locations: LocationProximity[];
    onSelect: (id: LocationID) => void;
}) => (
    <ol className={styles.ol}>
        {props.locations.map((locProx, i) => (
            <LocationListItem
                key={`${isoLocationID.unwrap(locProx.location.id)}`}
                index={i}
                onSelect={() => {
                    props.onSelect(locProx.location.id);
                }}
                {...locProx}
            />
        ))}
    </ol>
);
