import React, { Fragment } from "react";

import { Context, FooterLegalText } from "@reactivated";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";

import styles from "./FooterLegal.module.scss";

export const FooterLegal = (props: { value: FooterLegalText }) => {
    const context = React.useContext(Context);
    const footerLogo = context.core_assets.logo;

    return (
        <div className={styles.legalContainer}>
            <img src={footerLogo} className={styles.footerLogo} />
            {props.value.copyright && (
                <RichText
                    className={styles.footerCopyright}
                    value={props.value.copyright}
                />
            )}
            <div className={styles.legalLinks}>
                {props.value.links?.map((link, l) =>
                    link.url ? (
                        <Clickable
                            key={l}
                            href={link.url}
                            className={styles.link}
                        >
                            {link.display_name}
                        </Clickable>
                    ) : (
                        <Fragment key={l}></Fragment>
                    ),
                )}
            </div>
            {props.value.accessibility && (
                <RichText
                    className={styles.footerAccessibility}
                    value={props.value.accessibility}
                />
            )}
        </div>
    );
};
