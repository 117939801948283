import React, { useId, useState } from "react";
import { t } from "ttag";

import {
    Context,
    ThelabuiTypesLanguage as Language,
    reverse,
} from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const SearchForm = (props: {
    initialQuery?: string;
    language?: Language;
    className?: string;
}) => {
    const context = React.useContext(Context);
    const [query, setQuery] = useState(props.initialQuery);
    const queryID = useId();
    const action = context.wagtail_links.search || reverse("cms:search");
    return (
        <form
            className={concatClassNames([styles.form, props.className])}
            method="GET"
            action={action}
        >
            <TextField
                label={t`Search`}
                labelVisible={false}
                type="search"
                value={query}
                id={queryID}
                name="query"
                placeholder={t`Search`}
                onChange={(value) => setQuery(value)}
            />
            <ButtonPrimary type="submit" className={styles.submit}>
                <Svg
                    name="search"
                    visuallyHiddenText={t`Search`}
                    className={styles.svg}
                />
            </ButtonPrimary>
        </form>
    );
};
