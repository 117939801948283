import React from "react";
import { Helmet } from "react-helmet-async";

import {
    ThelabuiTypesAlternatePage as AlternatePage,
    Context,
    ThelabuiTypesLanguage as Language,
    reverse,
} from "@reactivated";

import { MetaTagsBlock } from "@thelabnyc/thelabui/src/components/MetaTagsBlock";
import { unique } from "@thelabnyc/thelabui/src/utils/functional";

import { FooterNavigation } from "../FooterNav";
import { PageNavWrapper } from "../PageNav";
import { ScrollDown } from "../ScrollDown";

import "../../styles/global.scss";

interface Props {
    title: string;
    seo_title?: string;
    search_description?: string;
    language: Language | null;
    alternate_pages: AlternatePage[];
    robots?: string;
    rootClassName?: string;
    children?: React.ReactNode;
}

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    const rootTitle = context.root_page.seo_title || context.root_page.title;
    const titleParts = [props.seo_title || props.title, rootTitle]
        .filter(unique)
        .join(" | ");
    return (
        <>
            <Helmet
                htmlAttributes={{
                    "lang": props.language?.code,
                    "xml:lang": props.language?.code,
                    "dir": props.language?.dir,
                }}
            >
                <meta charSet="utf-8" />
                <title>{titleParts}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                {MetaTagsBlock({
                    tags: context.meta_tags.meta_tags.value,
                    cspNonce: context.request.csp_nonce,
                })}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="48x48"
                    href={context.core_assets.favicon_png}
                />
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href={context.core_assets.favicon_svg}
                />
                <link
                    rel="shortcut icon"
                    href={context.core_assets.favicon_ico}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={context.core_assets.apple_touch_icon}
                />
                <meta name="apple-mobile-web-app-title" content={rootTitle} />
                <link
                    rel="manifest"
                    href={reverse("cms-api:pwa-site-manifest")}
                />
                {props.alternate_pages.map((alt) => (
                    <link
                        key={alt.language.code}
                        rel="alternate"
                        type="text/html"
                        title={alt.title}
                        hrefLang={alt.language.code}
                        href={alt.url}
                    />
                ))}
            </Helmet>
            <ScrollDown />
            <PageNavWrapper nav={context.nav} />
            <div className={props.rootClassName}>{props.children}</div>
            {context.footer?.body.value && (
                <FooterNavigation value={context.footer?.body.value} />
            )}
        </>
    );
};
