import React from "react";
import { t } from "ttag";

import { FavoritesContext } from "../../favorites";
import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./FavoritesList.module.scss";

interface Props {
    className?: string;
    favs: React.ContextType<typeof FavoritesContext>;
}

export const FavoritesList = ({ className, favs }: Props) => {
    return (
        <section className={className}>
            <h2 className={styles.heading}>{t`Your Favorites`}</h2>
            {favs.items.length > 0 ? (
                <ul className={styles.ul}>
                    {favs.items.map((product) => (
                        <li key={product.sku} className={styles.li}>
                            <Svg
                                name="heart-filled"
                                aria-hidden="true"
                                className={styles.heartFilled}
                            />
                            <Clickable
                                href={product.url}
                                className={styles.clickable}
                            >
                                <span>{product.name}</span>
                                <span>{product.sku}</span>
                            </Clickable>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className={styles.li}>
                    <Svg
                        className={styles.heart}
                        name="heart"
                        aria-hidden="true"
                    />
                    <span>{t`No favorites selected`}</span>
                </p>
            )}
        </section>
    );
};
