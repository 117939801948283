import React, { Fragment } from "react";

import { GridBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    id?: string;
}

export const GridBlock = ({ value, id }: Props) => {
    if (!value || !value.items) return <></>;

    return (
        <div
            className={
                value.columns === "two" ? styles.rootTwo : styles.rootThree
            }
        >
            <div className={styles.inner}>
                {value.items.map((item, index) => {
                    const key = `${id}-${index}`;
                    const hasContent = item.image || item.content;
                    const linkHref = item.link?.anchor
                        ? `#${item.link.anchor}`
                        : item.link?.url;
                    const itemClassName =
                        value.columns === "two"
                            ? styles.itemTwo
                            : styles.itemThree;

                    if (!hasContent) return <Fragment key={key}></Fragment>;

                    const inner = (
                        <>
                            {item.image && (
                                <ImageChooserBlock
                                    value={item.image}
                                    attrs={{ className: styles.picture }}
                                />
                            )}
                            {item.content && (
                                <RichText
                                    value={item.content}
                                    className={styles.richText}
                                />
                            )}
                        </>
                    );

                    return linkHref ? (
                        <Clickable
                            key={key}
                            href={linkHref}
                            className={itemClassName}
                        >
                            {inner}
                        </Clickable>
                    ) : (
                        <div className={itemClassName} key={key}>
                            {inner}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
