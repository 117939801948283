import { useEffect, useState } from "react";

import { reverse } from "@reactivated";

import {
    LatLng,
    LocationQueryResponse,
    Meters,
    check,
    isoLatitude,
    isoLongitude,
    isoMeters,
} from "../models";

export type UserLocation = {
    detected: LatLng;
    entered: LatLng | null;
};

export type LocationQuery = {
    center: LatLng;
    radius: Meters;
    limit: number;
};

const QUERY_RADIUS = isoMeters.wrap(100_000);
const QUERY_LIMIT = 25;

const DEFAULT_LOCATION: UserLocation = {
    detected: {
        lat: isoLatitude.wrap(40.889),
        lng: isoLongitude.wrap(-73.941),
    },
    entered: null,
};

/**
 * Use the google maps API to geocode text into coordinates
 */
export const geocode = async (
    request: google.maps.GeocoderRequest,
): Promise<google.maps.GeocoderResult[]> => {
    const geocoder = new google.maps.Geocoder();
    const resp = await geocoder.geocode(request);
    return resp.results;
};

/**
 * Query the retail location API for the locations within the circle defined by
 * the center and radius parameters.
 */
export const listLocations = async (
    query: LocationQuery,
): Promise<LocationQueryResponse> => {
    const url = reverse("cms-api:list-locations");
    const querystring = new URLSearchParams({
        lat: `${isoLatitude.unwrap(query.center.lat)}`,
        lng: `${isoLongitude.unwrap(query.center.lng)}`,
        radius: `${isoMeters.unwrap(query.radius)}`,
        limit: `${query.limit}`,
    });
    const response = await fetch(`${url}?${querystring.toString()}`);
    const data: unknown = await response.json();
    return check(LocationQueryResponse.decode(data));
};

/**
 * React hook for getting the user's current geolocation, and allowing them to
 * optionally override the detected location.
 */
export const useUserLocation = () => {
    const [userLocation, setUserLocation] =
        useState<UserLocation>(DEFAULT_LOCATION);

    // Find the user's location using the navigator.geolocation API
    const getBrowserGeoLocation = () => {
        const onSuccess: PositionCallback = (pos) => {
            setUserLocation((s) => ({
                ...s,
                detected: {
                    lat: isoLatitude.wrap(pos.coords.latitude),
                    lng: isoLongitude.wrap(pos.coords.longitude),
                },
            }));
        };
        const onError: PositionErrorCallback = (err) => {
            console.warn(err);
        };
        const options: PositionOptions = {
            enableHighAccuracy: false,
        };
        navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    };

    useEffect(() => {
        getBrowserGeoLocation();
    }, []);

    const setEnteredLocation = (enteredLocation: LatLng | null): void => {
        setUserLocation((s) => ({
            ...s,
            entered: enteredLocation,
        }));
    };

    return {
        userLocation,
        setEnteredLocation,
    };
};

/**
 * React hook for listing the nearby locations for the given user location
 */
export const useNearbyRetailLocations = (userLocation: UserLocation) => {
    const [queryResp, setQueryResp] = useState<LocationQueryResponse | null>(
        null,
    );

    const updateLocations = async (): Promise<void> => {
        const center = userLocation.entered || userLocation.detected;
        const resp = await listLocations({
            center: center,
            radius: QUERY_RADIUS,
            limit: QUERY_LIMIT,
        });
        setQueryResp(resp);
    };

    useEffect(() => {
        updateLocations().catch((err) => {
            console.error(err);
        });
    }, [userLocation]);

    return queryResp;
};
