import {
    format as formatDate,
    formatISO as formatISODate,
    parseISO as parseISODate,
} from "date-fns";
import React from "react";

import { CmsModelsPagesPressReleaseIndexPagePressReleaseTile as PressReleaseTile } from "@reactivated";

import { Clickable } from "../Clickables";

import styles from "./IndexItem.module.scss";

export const IndexItem = (props: { article: PressReleaseTile }) => {
    const pubDate = parseISODate(props.article.date);
    const isoPubDate = formatISODate(pubDate);

    return (
        <article
            className={styles.row}
            itemScope={true}
            itemType="http://schema.org/Article"
        >
            <time
                dateTime={isoPubDate}
                content={isoPubDate}
                itemProp="datePublished"
                className={styles.time}
            >
                {formatDate(pubDate, "MM/dd/yy")}
            </time>
            <div>
                <Clickable href={props.article.url}>
                    {props.article.title}
                </Clickable>
            </div>
        </article>
    );
};
