import React from "react";

import { CmsTemplatesSearchResult } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Clickable } from "../Clickables";
import { RichText } from "../RichText";
import { SearchResultBadges } from "./SearchResultBadges";

import styles from "./index.module.scss";

export const SearchResult = (props: { result: CmsTemplatesSearchResult }) => {
    const topBadges = props.result.search_badges.filter(
        (b) => b.location === "TOP",
    );
    const bottomBadges = props.result.search_badges.filter(
        (b) => b.location === "BOTTOM",
    );
    return (
        <article className={styles.result}>
            <Clickable href={props.result.url} className={styles.clickable}>
                <div className={styles.description}>
                    <SearchResultBadges badges={topBadges} />
                    <h2 className={styles.title}>{props.result.title}</h2>
                    {props.result.search_description && (
                        <RichText value={props.result.search_description} />
                    )}
                    <SearchResultBadges badges={bottomBadges} />
                </div>
                <div className={styles.image}>
                    {props.result.search_image && (
                        <ImageChooserBlock value={props.result.search_image} />
                    )}
                </div>
            </Clickable>
        </article>
    );
};
