import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { PressRelease } from "../components/PressRelease";

export const Template = (props: templates.PressReleasePageTemplate) => (
    <PageSkeleton {...props.page}>
        <main>
            <PressRelease page={props.page} />
        </main>
    </PageSkeleton>
);
