import React from "react";
import { VisuallyHidden } from "react-aria";
import type {
    ListBoxItemProps,
    SelectProps,
    ValidationResult,
} from "react-aria-components";
import {
    Select as BaseSelect,
    Button,
    FieldError,
    Label,
    ListBox,
    ListBoxItem,
    Popover,
    SelectValue,
    Text,
} from "react-aria-components";
import { t } from "ttag";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface Props<T extends object> extends Omit<SelectProps<T>, "children"> {
    /**
     * For accessibility benefits, there should always be a label, but it
     * doesn't have to be visible to all.
     */
    labelVisible?: boolean;
    label: string;
    /**
     * Select will be used in the footer with a one-off style, and in forms
     * with a reusable style.
     *
     * This was just an idea, improve/kill if it's bad.
     *
     * If nothing is entered, will default to "form"
     */
    theme?: "form" | "none";
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    items?: Iterable<T>;
    children: React.ReactNode | ((item: T) => React.ReactNode);
}

export const Select = <T extends object>({
    label,
    labelVisible = true,
    description,
    errorMessage,
    children,
    items,
    theme = "form",
    ...props
}: Props<T>) => {
    return (
        <BaseSelect {...props}>
            <Label
                className={concatClassNames([
                    styles.label,
                    props.isRequired ? styles.required : undefined,
                ])}
            >
                {labelVisible ? (
                    label
                ) : (
                    <VisuallyHidden>{label}</VisuallyHidden>
                )}
            </Label>
            <Button className={theme === "form" ? styles.formButton : ""}>
                <SelectValue />
                <Svg
                    name="caret-right"
                    className={styles.downArrow}
                    aria-hidden="true"
                />
            </Button>
            {description && (
                <Text className={styles.description} slot="description">
                    {description}
                </Text>
            )}
            <FieldError className={styles.errorMessage}>
                {({ validationDetails }) =>
                    validationDetails.valueMissing
                        ? t`Select an item in the list.`
                        : ""
                }
            </FieldError>
            <Popover className={theme === "form" ? styles.popover : ""}>
                <ListBox items={items} className={styles.listBox}>
                    {children}
                </ListBox>
            </Popover>
        </BaseSelect>
    );
};

export const SelectItem = <T extends object>(props: ListBoxItemProps<T>) => {
    return (
        <ListBoxItem<T>
            {...props}
            className={({ isFocused, isSelected }) =>
                concatClassNames([
                    styles.listBoxItem,
                    isFocused ? styles.focusedListBoxItem : "",
                    isSelected ? styles.selectedListBoxItem : "",
                ])
            }
        />
    );
};
