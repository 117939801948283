import React from "react";
import { t } from "ttag";

import {
    ImageBackgroundCarouselBlock as CarouselValue,
    ImageBackgroundBlock as Value,
} from "@reactivated";

import { HeroLayoutProps } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";

import Carousel from "../Carousel";
import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";

import styles from "./index.module.scss";

export interface Props {
    media: React.JSX.Element | null;
    content: React.JSX.Element | null;
    content_alignment: Value["content_alignment"];
}

export const ImageBackground = ({
    content,
    media,
    content_alignment,
}: Props) => {
    const sharedStyles = {
        rootClassName: content_alignment
            ? styles[content_alignment]
            : styles.root,
        mediaClassName: styles.media,
        contentClassName: styles.content,
    };

    const getDesktopAlignment = () => {
        switch (content_alignment) {
            case "right":
                return "right/bottom";
            case "left":
                return "left/top";
            default:
                return "middle";
        }
    };

    const desktopSettings: HeroLayoutProps = {
        content,
        media,
        settings: {
            heroDirection: "row",
            heroPlacement: "backgroundFull",
            mediaPlacement: "background",
            mediaAspectRatio: "16:9",
            contentAlignPrimary:
                content_alignment === "bottom" ? "right/bottom" : "middle",
            contentAlignSecondary: getDesktopAlignment(),
            textAlignment: content_alignment === "bottom" ? "center" : "left",
            contentWidth: content_alignment === "bottom" ? 6 : 4,
            maxHeight: 800,
        },
        styles: sharedStyles,
    };

    const laptopSettings: HeroLayoutProps = {
        ...desktopSettings,
        settings: {
            ...desktopSettings.settings,
            contentWidth: content_alignment === "bottom" ? 8 : 4,
        },
    };

    const tabletSettings: HeroLayoutProps = {
        ...desktopSettings,
        settings: {
            ...desktopSettings.settings,
            contentWidth: content_alignment === "bottom" ? 8 : 6,
        },
    };

    return (
        <HeroSet
            layouts={{
                desktop: desktopSettings,
                laptop: laptopSettings,
                tablet: tabletSettings,
                mobile: {
                    content,
                    media,
                    settings: {
                        heroDirection: "row",
                        heroPlacement: "backgroundFull",
                        mediaPlacement: "background",
                        mediaAspectRatio: "stretch",
                        contentAlignPrimary: "right/bottom",
                        contentAlignSecondary: "middle",
                        textAlignment: "center",
                        contentWidth: 12,
                        minHeight: 626,
                    },
                    styles: {
                        ...sharedStyles,
                        rootClassName: styles.bottom,
                    },
                },
            }}
        />
    );
};

export const ImageBackgroundBlock = ({ value }: { value: Value }) => {
    const content = value.content ? (
        <RichText value={value.content} applyLengthBasedStyles={true} />
    ) : null;

    const media = value.media ? (
        <MediaChooserBlock value={value.media} />
    ) : null;

    return (
        <ImageBackground
            content={content}
            media={media}
            content_alignment={value.content_alignment}
        />
    );
};

export const ImageBackgroundCarouselBlock = ({
    value: { title, slides },
    extraContent,
}: {
    value: CarouselValue;
    extraContent?: React.ReactNode;
}) => (
    <Carousel
        label={title || t`Carousel`}
        styles={{
            root: styles.carousel,
            nav: styles.carouselNav,
            navArrow: styles.navArrow,
            tabList: styles.carouselTabList,
            tab: styles.carouselTab,
            tabPanel: styles.carouselTabPanel,
        }}
    >
        {slides?.map((slide, index) => (
            <ImageBackground
                key={`${title?.replace(/\W+/g, "_") || ""}-${index}`}
                content={
                    slide.content ? (
                        <>
                            <RichText
                                value={slide.content}
                                applyLengthBasedStyles={true}
                            />
                            {extraContent}
                        </>
                    ) : null
                }
                media={
                    slide.media ? (
                        <MediaChooserBlock value={slide.media} />
                    ) : null
                }
                content_alignment="bottom"
            />
        ))}
    </Carousel>
);
