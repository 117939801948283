import React from "react";
import { t } from "ttag";

import {
    CharBlockOrNull,
    DocumentChooserBlockOrNull,
    RichTextBlockOrNull,
} from "@reactivated";

import { CRMForm, FormType, WhoAreYouSelect } from "../CRMForm";
import { StateSelect } from "../CRMForm/StateSelect";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { RichText } from "../RichText";
import { FormLayout } from "./CRMForm";

import styles from "./forms.module.scss";

interface Props extends FormLayout {
    legalText?: CharBlockOrNull;
    documentLink: DocumentChooserBlockOrNull;
    successContent: RichTextBlockOrNull;
}

export const DesignGuideDownloadForm = (props: Props) => {
    const handleSubmit = (resp: Response) => {
        if (resp.ok) {
            if (!props.documentLink) return;
            const link = document.createElement("a");
            link.href = props.documentLink.url;
            link.download = "download";
            link.dispatchEvent(new MouseEvent("click"));
        }
    };

    return (
        <CRMForm
            layout={props.layout}
            type={FormType.DESIGN_GUIDE_REQUEST_OPT_IN}
            onSubmit={handleSubmit}
            successState={
                props.successContent ? (
                    <RichText value={props.successContent} />
                ) : undefined
            }
        >
            {(disabled, legal) => (
                <>
                    <WhoAreYouSelect className={styles.input} />
                    <TextField
                        label={t`City`}
                        className={styles.input}
                        name="city"
                        isRequired
                    />
                    <StateSelect className={styles.input} />
                    <TextField
                        label={t`ZIP Code`}
                        className={styles.input}
                        name="zip_code"
                        isRequired
                    />
                    <TextField
                        label={t`Email Address`}
                        className={styles.input}
                        name="email_address"
                        isRequired
                        type="email"
                    />
                    {props.legalText ? (
                        <RichText
                            className={styles.legal}
                            value={props.legalText}
                        />
                    ) : (
                        legal
                    )}
                    <div className={styles.submitContainer}>
                        <ButtonPrimary type="submit" disabled={disabled}>
                            {t`Download`}
                        </ButtonPrimary>
                    </div>
                </>
            )}
        </CRMForm>
    );
};
