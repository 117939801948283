import React from "react";

import { ServerCmsTypesPageAd as Value } from "@reactivated";

import { constants } from "@reactivated/constants";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { ButtonPrimary } from "../Clickables";
import { ImageBackground } from "../ImageBackground";
import { RichText } from "../RichText";
import { Split } from "../SplitBlock";

import styles from "./index.module.scss";

const AdLayout = constants["server.cms.types.PageAdLayout"];
const PageAdContentLayout = constants["server.cms.types.PageAdContentLayout"];

const contentLayouts = {
    [PageAdContentLayout.TEXT_LEFT]: "left",
    [PageAdContentLayout.TEXT_RIGHT]: "right",
} as const;

export const PageAdLayout = (props: { value: Value }) => {
    const layout = props.value.layout as keyof typeof PageAdContentLayout;
    const contentLayout = contentLayouts[layout];

    const content = (
        <div className={styles.content}>
            {props.value.subtitle && (
                <p className={styles.eyebrow}>{props.value.subtitle}</p>
            )}
            <h2 className={styles.title}>{props.value.title}</h2>
            {props.value.description.html !== "" && (
                <RichText value={props.value.description.html} />
            )}
            <ButtonPrimary href={props.value.cta.url}>
                {props.value.cta.text}
            </ButtonPrimary>
        </div>
    );

    const media = props.value.image && (
        <ImageChooserBlock value={props.value.image} />
    );

    if (props.value.image_mode === AdLayout.HALF) {
        return (
            <Split
                content={content}
                media={media}
                contentAlignment={contentLayout}
                imageWidth="half"
            />
        );
    }
    if (props.value.image_mode === AdLayout.FULL) {
        return (
            <ImageBackground
                content={content}
                media={media}
                content_alignment={contentLayout}
            />
        );
    }
    return <></>;
};
