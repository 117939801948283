import React, { useContext } from "react";
import { t } from "ttag";

import { FavoritesContext } from "../../favorites";
import { CRMForm, FormType, WhoAreYouSelect } from "../CRMForm";
import { StateSelect } from "../CRMForm/StateSelect";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { FormLayout } from "./CRMForm";

import styles from "./forms.module.scss";

interface Props extends FormLayout {
    title?: string;
}

export const RequestAQuoteForm = (props: Props) => {
    const favs = useContext(FavoritesContext);

    return (
        <CRMForm
            layout={props.layout}
            type={FormType.REQUEST_A_QUOTE}
            title={props.title}
        >
            {(disabled, legal) => (
                <>
                    <WhoAreYouSelect className={styles.input} />
                    <TextField
                        label={t`First Name`}
                        className={styles.input}
                        name="first_name"
                        isRequired
                    />
                    <TextField
                        label={t`Last Name`}
                        className={styles.input}
                        name="last_name"
                        isRequired
                    />
                    <TextField
                        label={t`Email Address`}
                        className={styles.input}
                        name="email_address"
                        isRequired
                        type="email"
                    />
                    <TextField
                        label={t`Phone Number`}
                        className={styles.input}
                        name="phone"
                    />

                    <TextField
                        label={t`City`}
                        className={styles.input}
                        name="city"
                        isRequired
                    />
                    <StateSelect className={styles.input} />
                    <TextField
                        label={t`ZIP Code`}
                        className={styles.input}
                        name="zip_code"
                        isRequired
                    />
                    <TextField
                        label={t`If you are working with a dealer, please specify the dealer name`}
                        className={styles.input}
                        name="dealer_name"
                        isRequired
                    />
                    <TextField
                        label={t`Message`}
                        className={styles.input}
                        name="message"
                        textArea={true}
                    />
                    <input
                        type="hidden"
                        name="reason_for_this_email"
                        value="Request a Quote"
                    />
                    {favs.items.length > 0 && (
                        <input
                            type="hidden"
                            name="favorites_list"
                            value={JSON.stringify(favs.items)}
                        />
                    )}
                    {legal}
                    <div className={styles.submitContainer}>
                        <ButtonPrimary disabled={disabled} type="submit">
                            {t`Request a Quote`}
                        </ButtonPrimary>
                    </div>
                </>
            )}
        </CRMForm>
    );
};
