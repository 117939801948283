import React from "react";

import { LocationProximity } from "../../models";
import { Clickable } from "../Clickables";
import { Svg } from "../Svg";
import { Address } from "./Address";
import { Distance } from "./Distance";
import { IndexGlyph } from "./IndexGlyph";
import { PhoneNumber } from "./PhoneNumber";
import { TodaysHours } from "./TodaysHours";

import styles from "./LocationListItem.module.scss";

type LocationListItemProps = LocationProximity & {
    index: number;
    onSelect: () => void;
};

export const LocationListItem = (props: LocationListItemProps) => {
    return (
        <li className={styles.li} data-id={props.location.id}>
            <Clickable onPress={props.onSelect}>
                <IndexGlyph index={props.index} />
                <h3 className={styles.locationName}>
                    {props.location.display_name}
                </h3>
            </Clickable>
            <Distance distance={props.distance} />
            <Address location={props.location} />
            {props.location.website && (
                <Clickable
                    href={props.location.website}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.itemWithIcon}
                >
                    <Svg
                        name="external"
                        aria-hidden="true"
                        className={styles.svg}
                    />
                    <span className={styles.textLink}>
                        {props.location.website}
                    </span>
                </Clickable>
            )}
            {props.location.phone && (
                <PhoneNumber phone={props.location.phone} />
            )}
            <TodaysHours />
        </li>
    );
};
