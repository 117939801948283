import React from "react";
import { Key, VisuallyHidden } from "react-aria";
import {
    ComboBox,
    Input,
    Label,
    ListBox,
    ListBoxItem,
    Popover,
} from "react-aria-components";
import { t } from "ttag";

import {
    ServerCmsModelsPagesDownloadLibraryPageAutocompleteEntry as AutocompleteEntry,
    ServerCmsModelsPagesDownloadLibraryPageBreadcrumb as Breadcrumb,
} from "@reactivated";

import { ButtonSecondary } from "../Clickables";

import styles from "./index.module.scss";

export const DownloadLibraryNav = (props: {
    autocomplete: AutocompleteEntry[];
    breadcrumbs: Breadcrumb[];
}) => {
    const handleSelectionChange = (key: Key | null) => {
        if (!key) return;
        window.location.href = key.toString();
    };

    return (
        <nav className={styles.nav}>
            <div>
                <h2 className={styles.title}>{t`Browse`}</h2>
                <ul className={styles.breadcrumbs}>
                    {props.breadcrumbs.map((page) => (
                        <li key={page.url}>
                            {page.url ? (
                                <ButtonSecondary href={page.url}>
                                    {page.title}
                                </ButtonSecondary>
                            ) : (
                                <span>{page.title}</span>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <ComboBox onSelectionChange={handleSelectionChange}>
                <VisuallyHidden>
                    <Label>{t`Search by product name or #`}</Label>
                </VisuallyHidden>
                <div>
                    <Input
                        placeholder={t`Search by product name or #`}
                        type="search"
                        className={styles.input}
                    />
                </div>
                <Popover className={styles.popover}>
                    <ListBox>
                        {props.autocomplete.map((entry) => (
                            <ListBoxItem
                                key={entry.sku}
                                id={entry.url}
                                className={styles.listBoxItem}
                                // value={entry.sku}
                            >
                                {t`${entry.sku}: ${entry.title}`}
                            </ListBoxItem>
                        ))}
                    </ListBox>
                </Popover>
            </ComboBox>
            {/* <div>
                <input
                    type="search"
                    placeholder={t`Search by product name or #`}
                    list={productDatalistID}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.currentTarget.value);
                    }}
                />
                <datalist id={productDatalistID}>
                    {props.autocomplete.map((entry) => (
                        <option key={entry.sku} value={entry.sku}>
                            {t`${entry.sku}: ${entry.title}`}
                        </option>
                    ))}
                </datalist>
            </div> */}
        </nav>
    );
};
