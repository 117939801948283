import React from "react";

import { CmsTemplatesSearchResult } from "@reactivated";

import { SearchResult } from "../SearchResult";

import styles from "./index.module.scss";

export const SearchResults = (props: {
    results: CmsTemplatesSearchResult[];
}) => (
    <ol className={styles.results}>
        {props.results.map((result) => (
            <li key={result.id}>
                <SearchResult result={result} />
            </li>
        ))}
    </ol>
);
