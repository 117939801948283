import React, { createRef, useContext, useEffect, useState } from "react";
import { VisuallyHidden } from "react-aria";
import { t } from "ttag";

import { Context, VideoChooserBlock as Value } from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable, ClickableProps } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const VideoPlayerControls = ({
    value,
    wrapperAttrs,
    attrs,
    controlAttrs,
    type = "background",
}: {
    value: Value;
    wrapperAttrs?: React.HTMLAttributes<HTMLDivElement>;
    attrs?: React.HTMLAttributes<HTMLVideoElement>;
    controlAttrs?: ClickableProps;
    type?: null | ("background" | "foreground");
}) => {
    const videoRef = createRef<HTMLVideoElement>();

    // Background videos are preloaded so that they can be autoplayed.
    // Foreground videos aren't loaded until the user interacts.
    const willPreload = type === "background";

    // Tracks whether or not the video loadeddata event has been called yet.
    const [isPlayable, setIsPlayable] = useState(false);

    // Tracks whether or not the video _should_ be playing.
    const [isPlaying, setIsPlaying] = useState(true);

    // Tracks whether or not the video is currently loading.
    const [isLoading, setIsLoading] = useState(willPreload);

    // Tracks whether or not the video is currently visible in the viewport.
    const [isVisible, setIsVisible] = useState(false);

    // Tracks whether or not the video should have sources yet.
    const [loadVideoSources, setLoadVideoSources] = useState(false);

    const toggleIsPlaying = () => {
        setIsPlaying((isPlaying) => !isPlaying);
    };

    const { LOCALE } = useContext(Context);

    /**
     * When the video first becomes visible, load the sources.
     */
    useEffect(() => {
        if (!videoRef.current) {
            return;
        }
        if (isVisible) {
            setLoadVideoSources(true);
        }
        /**
         * Manually set visibility of subtitles. If a track matches the active
         * locale it should be on by default, unless it's English. All other
         * tracks should be hidden.
         *
         * This overrides default browser behavior which seems to favor turning
         * on a subtitle by default if it's there as well as using the browser's/
         * system's language setting to determine the track.
         */
        [...videoRef.current.textTracks].forEach((track) => {
            const trackLanguage = track.language.substring(0, 2);
            const activeLanguage = LOCALE.substring(0, 2);
            if (trackLanguage === activeLanguage && trackLanguage !== "en") {
                track.mode = "showing";
            } else {
                track.mode = "hidden";
            }
        });
    }, [videoRef.current, isVisible]);

    /**
     * If this is a background video, autoplay it as soon as it's visible and
     * has finished loading.
     */
    useEffect(() => {
        if (!videoRef.current || type !== "background") {
            return;
        }
        if (!isLoading && isVisible && isPlayable) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    }, [videoRef.current, type, isLoading, isVisible, isPlayable]);

    /**
     * When the isPlaying state changes, update the DOM state to match.
     */
    useEffect(() => {
        if (!videoRef.current) {
            return;
        }
        if (isPlayable && isPlaying) {
            void videoRef.current.play();
        } else {
            void videoRef.current.pause();
        }
    }, [videoRef.current, isPlaying]);

    /**
     * Wait until after everything else is done loading to add sources to the
     * video element. TODO: @Brendan Why do we do this?
     */
    useEffect(() => {
        const contentLoaded = () => {
            if (document.readyState === "complete") {
                setLoadVideoSources(true);
            }
        };
        document.addEventListener("readystatechange", contentLoaded);
        return () => {
            document.removeEventListener("readystatechange", contentLoaded);
        };
    }, []);

    /**
     * When the video element finishes loading metadata, set the playable boolean.
     */
    useEffect(() => {
        const setPlayable = () => {
            setIsPlayable(true);
        };
        const videoElem = videoRef.current;
        if (videoElem) {
            videoElem.addEventListener("loadeddata", setPlayable);
        }
        return () => {
            if (videoElem) {
                videoElem.removeEventListener("loadeddata", setPlayable);
            }
        };
    }, [videoRef.current]);

    /**
     * Track if the video element is visible or not, and update the corresponding state bool.
     */
    useEffect(() => {
        const callback: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (!(entry.target instanceof HTMLVideoElement)) return;
                setIsVisible(entry.isIntersecting);
            });
        };
        const observer = new IntersectionObserver(callback, {
            threshold: 0.1,
        });
        const videoElem = videoRef.current;
        if (videoElem) {
            observer.observe(videoElem);
        }
        return () => {
            if (videoElem) {
                observer.unobserve(videoElem);
            }
        };
    }, [videoRef.current]);

    const modifiedValue = {
        ...value,
        sources: loadVideoSources ? value.sources : [],
    };

    return (
        <div
            {...wrapperAttrs}
            className={concatClassNames([
                styles.root,
                wrapperAttrs?.className || "",
            ])}
            style={{
                aspectRatio: modifiedValue.aspectRatio || "16/9",
            }}
        >
            {isLoading && (
                <>
                    <div className={styles.loaderOverlay} aria-hidden="true" />
                    <div className={styles.loaderContainer} aria-hidden="true">
                        <div className={styles.loader} />
                    </div>
                </>
            )}
            <VideoChooserBlock
                ref={videoRef}
                iOSFriendlyMutedAutoPlay={type === "background"}
                attrs={{
                    loop: type === "background",
                    controls: type === "foreground",
                    preload: willPreload ? "auto" : "none",
                    onLoadStart: () => {
                        // When preload is set to "none", this still gets called
                        // right away, but none of the "loading finished" events
                        // will get called (since nothing is actually loading yet).
                        // Thus, only get isLoading is preload is enabled.
                        if (willPreload) {
                            setIsLoading(true);
                        }
                    },
                    onWaiting: () => {
                        setIsLoading(false);
                    },
                    onPlaying: () => {
                        setIsLoading(false);
                    },
                    onCanPlayThrough: () => {
                        setIsLoading(false);
                    },
                    onPause: () => {
                        setIsPlaying(false);
                    },
                    onPlay: () => {
                        setIsPlaying(true);
                    },
                    ...attrs,
                }}
                value={modifiedValue}
            />
            <Clickable
                disabled={isLoading}
                onPress={toggleIsPlaying}
                {...controlAttrs}
                className={concatClassNames([
                    type === "foreground"
                        ? styles.foregroundPlayControl
                        : styles.backgroundPlayControl,
                    isPlaying ? styles.playing : "",
                    controlAttrs?.className || "",
                ])}
            >
                <VisuallyHidden>
                    {isPlaying ? t`Pause Video` : t`Play Video`}
                </VisuallyHidden>
                <Svg
                    name={isPlaying ? "pause" : "play"}
                    className={styles.icon}
                    aria-hidden="true"
                />
            </Clickable>
        </div>
    );
};
