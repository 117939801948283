import React, { useEffect, useState } from "react";
import { t } from "ttag";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const ScrollDown = () => {
    const [visible, setVisible] = useState(false);

    const handlePress = () => {
        if (!window) return;
        window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
        setVisible(false);
    };

    useEffect(() => {
        if (!window || !document) return;

        if (
            window.scrollY < 200 &&
            document.body.scrollHeight > window.innerHeight
        )
            setVisible(true);

        const handleScroll = () => setVisible(false);

        window.addEventListener("scroll", handleScroll);

        return () => {
            if (!window) return;
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Clickable
            onPress={handlePress}
            className={visible ? styles.clickable : styles.hiddenClickable}
        >
            <Svg
                name="caret-right"
                visuallyHiddenText={t`More Content`}
                className={styles.svg}
            />
        </Clickable>
    );
};
