import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { PageChildren } from "../components/PageChildren";
import { PageSkeleton } from "../components/PageSkeleton";
import { toolboxComponents } from "../components/PageSkeleton/Toolbox";

export const Template = (props: templates.CategoryPageTemplate) => (
    <PageSkeleton {...props.page}>
        <StreamField
            tagName={React.Fragment}
            value={props.page.body.value}
            components={{
                ...toolboxComponents,
                page_children: (blockProps) => (
                    <PageChildren
                        {...blockProps}
                        pageChildren={props.page.children}
                    />
                ),
            }}
        />
    </PageSkeleton>
);
