import React from "react";

import {
    ServerCmsModelsPagesMixinsDownloadLibraryTile as Tile,
    reverse,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { DocumentList } from "../ProductDetails/DocumentList";

import styles from "./index.module.scss";

export const DownloadLibraryProduct = (props: { product: Tile }) => {
    return (
        <section className={styles.section}>
            <div>
                {props.product.image && (
                    <ImageChooserBlock value={props.product.image} />
                )}
            </div>
            <div>
                <p className={styles.eyebrow}>{props.product.eyebrow}</p>
                <h1 className={styles.title}>{props.product.title}</h1>
                <DocumentList
                    value={props.product.documents}
                    downloadsUrl={reverse("cms-api:download-documents")}
                />
            </div>
        </section>
    );
};
