import React from "react";
import { t } from "ttag";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { SearchForm } from "../components/SearchForm";
import { SearchResults } from "../components/SearchResults";

import styles from "../styles/search-results-page.module.scss";

export const Template = (props: templates.SearchResultsTemplate) => (
    <PageSkeleton
        title={t`Search Results`}
        language={props.language}
        alternate_pages={[]}
        rootClassName={styles.root}
    >
        <SearchForm
            className={styles.resultsPage}
            initialQuery={props.query}
            language={props.language}
        />
        <SearchResults results={props.results} />
    </PageSkeleton>
);
