import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { CenteredHeroWithModal } from "../components/CenteredHero/CenteredHeroWithModal";
import { DownloadLibraryNav } from "../components/DownloadLibraryNav";
import { DownloadLibrarySection } from "../components/DownloadLibrarySection";
import { PageSkeleton } from "../components/PageSkeleton";
import { toolboxComponents } from "../components/PageSkeleton/Toolbox";

export const Template = (
    props: templates.DownloadLibraryPageProductsTemplate,
) => (
    <PageSkeleton {...props.page}>
        <StreamField
            components={{
                ...toolboxComponents,
                centered_hero: CenteredHeroWithModal,
                download_library: () => (
                    <>
                        <DownloadLibraryNav
                            breadcrumbs={props.breadcrumbs}
                            autocomplete={props.autocomplete}
                        />
                        <DownloadLibrarySection
                            heading={props.category.title}
                            tiles={props.products}
                        />
                    </>
                ),
            }}
            value={props.page.body.value}
            tagName={React.Fragment}
        />
    </PageSkeleton>
);
