import { TabbedBlock } from "@client/components/TabbedBlock";
import React from "react";

import { templates } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { CenteredHeroBlock } from "../components/CenteredHero";
import { PageSkeleton } from "../components/PageSkeleton";
import { toolboxComponents } from "../components/PageSkeleton/Toolbox";
import { SplitBlockWithValueProps } from "../components/SplitBlockWithValueProps";

import styles from "../styles/home-page.module.scss";

export const Template = (props: templates.HomePageTemplate) => (
    <PageSkeleton {...props.page} rootClassName={styles.homePage}>
        <StreamField
            tagName={React.Fragment}
            value={props.page.body.value}
            components={{
                ...toolboxComponents,
                promo: (props) => (
                    <CenteredHeroBlock value={props.value} isPromo={true} />
                ),
                split_with_value_props: SplitBlockWithValueProps,
                tabbed_block: TabbedBlock,
            }}
        />
    </PageSkeleton>
);
