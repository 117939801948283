import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { RecipePageBody } from "../components/RecipePageBody";

export const Template = (props: templates.RecipePageTemplate) => (
    <PageSkeleton {...props.page}>
        <RecipePageBody
            title={props.page.title}
            hero={props.page.hero?.block || null}
            body={props.page.body.value}
        />
    </PageSkeleton>
);
