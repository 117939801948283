import { TypeOf } from "io-ts";
import React from "react";

import { Location } from "../../models";
import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./LocationListItem.module.scss";

// https://stackoverflow.com/a/9039885/
const iOS = () =>
    [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);

interface Props {
    location: TypeOf<typeof Location>;
}

export const Address = ({
    location: {
        line1,
        line2,
        line3,
        city,
        state,
        postcode,
        country,
        address_summary,
        display_name,
    },
}: Props) => {
    const mapQuery = `${display_name}, ${address_summary}`;
    const mapLink = iOS()
        ? `https://maps.apple.com/?daddr=${mapQuery}`
        : `https://www.google.com/maps/search/?api=1&query=${mapQuery}`;

    // Adapt format of line4 based on country
    let line4 = `${city}, ${state} ${postcode}`; // Default: US format
    if (country === "MX") {
        line4 = `${postcode} ${city}, ${state}`; // Mexico format
    }

    return (
        <Clickable
            className={styles.itemWithIcon}
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Svg name="directions" aria-hidden="true" className={styles.svg} />
            <address className={styles.textLink}>
                {line1}
                <br />
                {line2 && (
                    <>
                        {line2} <br />{" "}
                    </>
                )}
                {line3 && (
                    <>
                        {line3} <br />{" "}
                    </>
                )}
                {line4}
            </address>
        </Clickable>
    );
};
