import * as t from "io-ts";

import {
    ArrayRepository,
    LocalStorageBackend,
    newPersistentArrayContext,
} from "@thelabnyc/thelabui/src/hooks/persistent-state";

export const Favorite = t.type({
    name: t.string,
    sku: t.string,
    url: t.string,
});
export type Favorite = t.TypeOf<typeof Favorite>;

export const Favorites = t.array(Favorite);
export type Favorites = t.TypeOf<typeof Favorites>;

const STORAGE_KEY = "favorites";
const WRITE_LOCK_NAME = "favorites";
const BROADCAST_CHANNEL_NAME = "favorites";

const favoritesRepo = new ArrayRepository({
    backend: new LocalStorageBackend({
        codec: Favorites,
        defaultVal: [],
        storageKey: STORAGE_KEY,
    }),
    writeLockName: WRITE_LOCK_NAME,
    broadcastChannelName: BROADCAST_CHANNEL_NAME,
    keyProp: "sku",
});

const { Context: FavoritesContext, Provider: FavoritesProvider } =
    newPersistentArrayContext({
        repo: favoritesRepo,
    });

export { FavoritesContext, FavoritesProvider };

export const isFavorite = (favs: Favorites, product: Favorite): boolean => {
    const favSKUs = new Set(favs.map((f) => f.sku));
    return favSKUs.has(product.sku);
};
