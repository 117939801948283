import React, { Fragment } from "react";

import { BlockGroup as Value } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { ImageTextSlides } from "../ImageTextSlides";
import { RichTextBlock } from "../RichTextBlock";
import { SpacerBlock } from "../SpacerBlock";

import styles from "./index.module.scss";

export const BlockGroup = ({ value }: { value: Value }) => {
    if (!value.items) return <></>;

    return (
        <section className={styles[value.background_color ?? "transparent"]}>
            <StreamField
                tagName={Fragment}
                components={{
                    image_text_slides: ImageTextSlides,
                    rich_text: RichTextBlock,
                    spacer: SpacerBlock,
                }}
                value={value.items}
            />
        </section>
    );
};
