import React from "react";
import { t } from "ttag";

import { RichText } from "../RichText";

import styles from "./index.module.scss";

export const RecipeInstructionsBlock = (
    props: React.ComponentProps<typeof RichText>,
) => (
    <div className={styles.root}>
        <div className={styles.instructions}>
            <h2 className={styles.title}>{t`Method`}</h2>
            <RichText {...props} className={styles.text} />
        </div>
    </div>
);
