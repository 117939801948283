import React, { useContext } from "react";
import { t } from "ttag";

import {
    ServerCmsModelsPagesProductPageProductPageTemplate as Page,
    RequestQuoteBlock as Value,
} from "@reactivated";

import { FavoritesContext } from "../../favorites";
import { RequestAQuoteForm } from "../CRMForm/RequestAQuoteForm";
import { FavoritesList } from "../PageNav/FavoritesList";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    title: string;
    product_image: Page["page"]["downloadlib_tile_image"];
}

export const RequestQuoteBlock = (props: Props) => {
    const favs = useContext(FavoritesContext);
    return (
        <section className={styles.root} id="request-a-quote">
            <div className={styles.inner}>
                <div className={styles.column}>
                    <h2 className={styles.title}>{t`Request a Quote`}</h2>
                    <p className={styles.text}>
                        {t`
                            Ready to take things to the next level? We’re
                            happy to help, all we need is a little information.
                        `}
                    </p>
                    {props.product_image?.file && (
                        <img
                            className={styles.image}
                            src={props.product_image.file.url}
                            alt={t`Product Image`}
                        />
                    )}
                    <FavoritesList
                        favs={favs}
                        className={styles.favoritesList}
                    />
                </div>
                <div className={styles.column}>
                    <RequestAQuoteForm
                        title={props.title}
                        layout="complementary"
                    />
                </div>
            </div>
        </section>
    );
};
