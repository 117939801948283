import React, { forwardRef } from "react";
import {
    AriaButtonOptions,
    useButton,
    useFocusRing,
    useObjectRef,
} from "react-aria";

import { Clickable as BaseClickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { ClickableProps as BaseClickableProps } from "@thelabnyc/thelabui/src/components/Clickable/types";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { SVGName, Svg, allSvgNames } from "../Svg";

import styles from "./index.module.scss";

export type ClickableProps = BaseClickableProps &
    AriaButtonOptions<"button" | "a">;

export const Clickable = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>((props, ref) => {
    ref = useObjectRef(ref);
    const { buttonProps } = useButton(props, ref);
    const { focusProps, isFocused, isFocusVisible } = useFocusRing(props);
    const { onPress, isDisabled, ...propsWithoutOddballs } = props;

    return (
        <BaseClickable
            ref={ref}
            {...buttonProps}
            {...focusProps}
            {...propsWithoutOddballs}
            className={concatClassNames([styles.base, props.className])}
            data-focused={isFocused || undefined}
            data-focus-visible={isFocusVisible || undefined}
        >
            {props.children}
        </BaseClickable>
    );
});

type InnerProps = ClickableProps;

const Inner = ({ ...props }: InnerProps) => {
    let icon = <></>;
    if (
        typeof props.icon === "string" &&
        (allSvgNames as ReadonlyArray<string>).includes(props.icon)
    ) {
        icon = (
            <Svg
                name={props.icon as SVGName}
                visuallyHiddenText={null}
                aria-hidden={true}
            />
        );
    } else if (props.icon && typeof props.icon !== "string") {
        icon = props.icon;
    }
    return (
        <div className={styles.clickableContent}>
            {props.children}
            {icon}
        </div>
    );
};

export const ButtonPrimary = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonPrimary(props, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.primaryButton,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});

export const ButtonSecondary = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonSecondary(props, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.buttonSecondary,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});

export const ButtonPlayPause = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonPlayPause({ ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.playPauseButton,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});
