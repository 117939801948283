import React, { useState } from "react";
import { t } from "ttag";

import { geocode } from "../../api/locations";
import { LatLng, fromGoogleLatLng } from "../../models";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const UpdateLocationForm = (props: {
    onLocationUpdate: (point: LatLng) => void;
    onError: (error: string) => void;
}) => {
    const [inputText, setInputText] = useState("");

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        props.onError("");

        try {
            const results = await geocode({
                address: inputText,
            });
            const result = results[0];
            if (!result?.geometry?.location) {
                console.error("Result has no geometry info");
                return;
            }
            const point = fromGoogleLatLng(result.geometry.location);
            props.onLocationUpdate(point);
        } catch (error) {
            if (
                error !== null &&
                typeof error === "object" &&
                "code" in error
            ) {
                if (error.code === "ZERO_RESULTS") {
                    props.onError(
                        t`There are no results for what you entered.`,
                    );
                } else {
                    props.onError(
                        t`Something went wrong with what you entered. Try again,
                        or refresh the page if problems persist.`,
                    );
                }
            } else console.error("UpdateLocationForm", error);
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <h1 className={styles.title}>{t`Find a dealer`}</h1>
            <div className={styles.inputGroup}>
                <TextField
                    type="text"
                    label={t`Enter ZIP code or city`}
                    labelVisible={false}
                    placeholder={t`Enter ZIP code or city`}
                    autoComplete="postal-code"
                    value={inputText}
                    onChange={(value) => setInputText(value)}
                    className={styles.input}
                />
                <ButtonPrimary type="submit" className={styles.submit}>
                    <Svg
                        name="search"
                        visuallyHiddenText={t`Search`}
                        className={styles.searchIcon}
                    />
                </ButtonPrimary>
            </div>
        </form>
    );
};
