import React from "react";

import { RichText } from "../RichText";

import styles from "./index.module.scss";

export const RecipeAttributionBlock = (
    props: React.ComponentProps<typeof RichText>,
) => (
    <div className={styles.root}>
        <RichText {...props} className={styles.recipeAttribution} />
    </div>
);
