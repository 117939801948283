import { ServerCmsContextProcessorsNavigationItem as NavigationItem } from "@reactivated";

export const getChildren = (
    parent: NavigationItem,
    items: NavigationItem[],
): NavigationItem[] => {
    return items.filter((item) => {
        return (
            item.path.startsWith(parent.path) && item.depth === parent.depth + 1
        );
    });
};
