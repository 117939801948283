import React from "react";
import { t } from "ttag";

import { PageItem, PageListTab as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./page-list.module.scss";

interface Props extends PageItem {
    isFeaturedItem: boolean;
    layoutHasFeatured: boolean;
}

const PageCard = (props: Props) => {
    if (!props.link?.url) return <></>;

    return (
        <li
            className={props.isFeaturedItem ? styles.featuredCard : styles.card}
        >
            <article>
                <Clickable
                    href={props.link.url}
                    className={
                        props.isFeaturedItem
                            ? styles.featuredClickable
                            : undefined
                    }
                >
                    {props.image && (
                        <ImageChooserBlock
                            value={props.image}
                            attrs={{
                                className: props.isFeaturedItem
                                    ? styles.featuredPicture
                                    : styles.picture,
                            }}
                        />
                    )}
                    <div
                        className={
                            props.layoutHasFeatured
                                ? styles.minHeight
                                : undefined
                        }
                    >
                        {props.link.display_name && (
                            <h2>{props.link.display_name}</h2>
                        )}
                        <span className={styles.cta}>
                            {props.cta_text || t`Read More`}
                            <Svg
                                name="caret-right"
                                aria-hidden="true"
                                className={styles.svg}
                            />
                        </span>
                    </div>
                </Clickable>
            </article>
        </li>
    );
};

export const PageList = ({ pages }: { pages: Value["pages"] }) => {
    if (!pages) return <></>;

    const columns = Number.isInteger(4 / pages.length)
        ? styles.twoColumns
        : styles.threeColumns;
    const rows = pages.length > 3 ? styles.twoRows : styles.oneRow;

    return (
        <ul className={concatClassNames([columns, rows])}>
            {pages.map((page, index) => (
                <PageCard
                    key={`tab-page-${index}`}
                    {...page}
                    isFeaturedItem={pages.length === 5 && index === 2}
                    layoutHasFeatured={pages.length === 5}
                />
            ))}
        </ul>
    );
};
