import React from "react";

import { Context, LocatorMapBlock as Value } from "@reactivated";

import { ButtonPrimary } from "../Clickables";
import { LocatorMap } from "../LocatorMap";

import styles from "./index.module.scss";

export const LocatorMapBlock = (props: { value: Value }) => {
    const context = React.useContext(Context);

    return (
        <LocatorMap googleMapsApiKey={context.GOOGLE_MAPS_V3_APIKEY || ""}>
            {props.value.links && (
                <div className={styles.extraLinks}>
                    {props.value.links.map((link, i) => (
                        <ButtonPrimary key={i} href={link.url!}>
                            {link.display_name}
                        </ButtonPrimary>
                    ))}
                </div>
            )}
        </LocatorMap>
    );
};
