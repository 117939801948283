import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useRef } from "react";

import {
    LocationID,
    LocationProximity,
    isoLocationID,
    toGoogleLatLng,
} from "../../models";
import { mapPinD } from "../Svg";
import { getGlyph } from "./IndexGlyph";

import colorVariables from "../../styles/color-variables.module.scss";
import styles from "./LocationListItem.module.scss";

const containerStyle = {
    width: "100%",
    height: "100%",
};

export const MarkerMap = (props: {
    locations: LocationProximity[];
    focusedLocationID: LocationID | null;
    setFocusedLocationID: (rlid: LocationID | null) => void;
}) => {
    const mapRef = useRef<google.maps.Map | null>(null);
    const focusedMarkerRefs = useRef(new Map<LocationID, google.maps.Marker>());
    const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);

    const firstLocation =
        props.locations.length >= 1 ? props.locations[0] : null;
    const focusedLocation =
        props.locations.find((locProx) => {
            return locProx.location.id === props.focusedLocationID;
        }) || firstLocation;
    const focusedMarker = focusedLocation
        ? focusedMarkerRefs.current.get(focusedLocation.location.id)
        : null;

    // Automatically pan the map to the focused location
    if (focusedLocation && mapRef.current) {
        mapRef.current.panTo(toGoogleLatLng(focusedLocation.location.location));
    }
    // Move the info to the currently focused marker
    if (infoWindowRef.current && focusedMarker) {
        infoWindowRef.current.close();
        infoWindowRef.current.open({
            anchor: focusedMarker,
        });
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={10}
            onLoad={(map) => {
                mapRef.current = map;
            }}
        >
            {props.locations.map(({ location }, index) => (
                <Marker
                    key={`${isoLocationID.unwrap(location.id)}`}
                    label={{
                        text: getGlyph(index),
                        className: styles.markerGlyph,
                    }}
                    position={toGoogleLatLng(location.location)}
                    onLoad={(marker) => {
                        focusedMarkerRefs.current.set(location.id, marker);
                    }}
                    onUnmount={() => {
                        focusedMarkerRefs.current.delete(location.id);
                    }}
                    onClick={() => {
                        props.setFocusedLocationID(location.id);
                    }}
                    icon={{
                        path: mapPinD,
                        fillColor: colorVariables.white,
                        fillOpacity: 1,
                        scale: 0.67,
                        strokeColor: colorVariables.red,
                        strokeWeight: 2,
                        labelOrigin: new google.maps.Point(21.5, 23),
                    }}
                />
            ))}
        </GoogleMap>
    );
};
