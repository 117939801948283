import React from "react";
import { t } from "ttag";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./close-button.module.scss";

interface Props {
    closeText?: string;
    onPress: () => void;
}

export const CloseButton = ({ closeText = t`Close`, onPress }: Props) => (
    <Clickable className={styles.close} onPress={onPress}>
        <Svg name="x" visuallyHiddenText={closeText} />
    </Clickable>
);
