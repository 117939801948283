import React from "react";
import { t } from "ttag";

import {
    SplitCarouselBlock as CarouselValue,
    SplitBlock as Value,
} from "@reactivated";

import {
    HeroLayoutSettings,
    HeroPlacement,
} from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";

import Carousel from "../Carousel";
import { MediaChooserBlock } from "../MediaChooserBlock";
import { RichText } from "../RichText";

import styles from "./index.module.scss";

export interface Props {
    media: React.JSX.Element | null;
    content: React.JSX.Element | null;
    contentAlignment: Value["content_alignment"];
    imageWidth: Value["image_width"];
    heroPlacement?: HeroPlacement;
    contentClassName?: string;
}

export const Split = ({
    content,
    media,
    contentAlignment,
    imageWidth,
    heroPlacement = "container",
    contentClassName = styles.content,
}: Props) => {
    /**
     * We have a use case where home page needs to see SplitBlocks.
     * This is one way to make it happen.
     */
    const staticClass = "splitLayoutDesktopStatic";

    const sharedStyles = { contentClassName };

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement,
        mediaPlacement: "inline",
        mediaAspectRatio: imageWidth === "half" ? "1:1" : "3:2",
        contentAlignPrimary: "middle",
        contentAlignSecondary:
            contentAlignment === "left" ? "left/top" : "right/bottom",
        textAlignment: "left",
        contentWidth: imageWidth === "half" ? 6 : 4,
    };

    return (
        <HeroSet
            layouts={{
                desktop: {
                    content,
                    media,
                    settings,
                    styles: {
                        ...sharedStyles,
                        rootClassName: staticClass,
                    },
                },
                tablet: {
                    content,
                    media,
                    settings: {
                        ...settings,
                        mediaAspectRatio: "1:1",
                        contentWidth: 6,
                    },
                    styles: sharedStyles,
                },
                mobile: {
                    content,
                    media,
                    settings: {
                        ...settings,
                        heroDirection: "column",
                        contentAlignSecondary: "right/bottom",
                        contentWidth: 12,
                        textAlignment: "center",
                    },
                    styles: sharedStyles,
                },
            }}
        />
    );
};

export const SplitBlock = ({ value }: { value: Value }) => {
    return (
        <Split
            content={value.content ? <RichText value={value.content} /> : null}
            media={value.media && <MediaChooserBlock value={value.media} />}
            contentAlignment={value.content_alignment}
            imageWidth={value.image_width}
        />
    );
};

export const SplitCarouselBlock = ({ value }: { value: CarouselValue }) => {
    if (!value.media) return <></>;

    return (
        <Split
            content={value.content ? <RichText value={value.content} /> : null}
            media={
                <Carousel
                    label={t`Carousel`}
                    styles={{
                        root: styles.carousel,
                        wrapper: styles.carouselWrapper,
                        inner: styles.carouselInner,
                        nav: styles.carouselNav,
                        navArrow: styles.navArrow,
                        tabList: styles.carouselTabList,
                        tab: styles.carouselTab,
                        tabPanel: styles.carouselTabPanel,
                    }}
                >
                    {value.media.map((slide) => (
                        <MediaChooserBlock key={slide[0].id} value={slide} />
                    ))}
                </Carousel>
            }
            contentAlignment={value.content_alignment}
            imageWidth={value.image_width}
        />
    );
};
