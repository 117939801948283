import React, { useContext, useState } from "react";
import {
    Dialog,
    DialogTrigger,
    OverlayArrow,
    Popover,
} from "react-aria-components";
import { t } from "ttag";

import { Context } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable, ClickableProps } from "../Clickables";
import { Svg } from "../Svg";

import stylesForPopover from "../PageNav/index.module.scss";
import styles from "./index.module.scss";

const CopyButton = (props: {
    url: string;
    children?: React.ReactNode;
    attrs?: ClickableProps;
    successClassName?: string;
    failureClassName?: string;
}) => {
    const [state, setState] = useState<"default" | "success" | "failure">(
        "default",
    );
    const attrs = props.attrs || {};
    attrs.className = props.attrs?.className || "";

    const copyUrl = () => {
        navigator.clipboard
            .writeText(props.url)
            .then(() => {
                setState("success");
                setTimeout(() => setState("default"), 2000);
            })
            .catch((error) => {
                console.log(error);
                setState("failure");
                setTimeout(() => setState("default"), 2000);
            });
    };

    console.log(state);

    return (
        <Clickable
            title="Copy URL"
            onPress={copyUrl}
            {...attrs}
            className={concatClassNames([
                attrs.className,
                state === "success"
                    ? (props.successClassName ?? styles.socialButtonSuccess)
                    : "",
                state === "failure"
                    ? (props.failureClassName ?? styles.socialButtonFailure)
                    : "",
            ])}
        >
            {state === "default" && props.children}
            {state === "success" && (
                <>
                    <Svg
                        name="check"
                        aria-hidden="true"
                        className={styles.clickableSvg}
                    />
                    {t`Copied!`}
                </>
            )}
            {state === "failure" && (
                <>
                    <Svg
                        name="x"
                        aria-hidden="true"
                        className={styles.clickableSvg}
                    />
                    {t`Error`}
                </>
            )}
        </Clickable>
    );
};

export const Share = ({ title }: { title: string }) => {
    const context = useContext(Context);

    return (
        <DialogTrigger>
            <Clickable className={styles.trigger}>
                <Svg
                    className={styles.svg}
                    name="share"
                    visuallyHiddenText={t`Share`}
                />
            </Clickable>
            <Popover className={stylesForPopover.popover} placement="bottom">
                <OverlayArrow className={stylesForPopover.overlayArrow}>
                    <svg width={12} height={12} viewBox="0 0 12 12">
                        <path d="M0 0 L6 6 L12 0" />
                    </svg>
                </OverlayArrow>
                <Dialog className={stylesForPopover.searchDialog}>
                    <ul className={styles.dialog}>
                        <li>
                            <CopyButton
                                url={context.request.url}
                                attrs={{ className: styles.clickable }}
                            >
                                <Svg
                                    name="copy"
                                    aria-hidden="true"
                                    className={styles.clickableSvg}
                                />
                                {t`Copy link`}
                            </CopyButton>
                        </li>
                        <li>
                            <Clickable
                                href={`mailto:?subject=${title}&body=${context.request.url}`}
                                className={styles.clickable}
                            >
                                <Svg
                                    name="mail"
                                    visuallyHiddenText={t`Copy link`}
                                    className={styles.clickableSvg}
                                />
                                {t`Email`}
                            </Clickable>
                        </li>
                    </ul>
                </Dialog>
            </Popover>
        </DialogTrigger>
    );
};
