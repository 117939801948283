import React, { Fragment } from "react";
import { t } from "ttag";

import {
    Money,
    SpecificationDetailBlockListOrNull,
    SpecificationDetailBlock as Value,
} from "@reactivated";

import { assertNever } from "@thelabnyc/thelabui/src/utils/never";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { RichText } from "../RichText";
import { Svg } from "../Svg";

import styles from "./specification-details.module.scss";

interface Props {
    value: SpecificationDetailBlockListOrNull | undefined;
    umrp: Money | null;
}

type SectionTitles = Exclude<Value["section"], null>;

const mappedSectionTitle = (title: SectionTitles) => {
    switch (title) {
        case "generalDimensions":
            return t`General Dimensions`;
        case "includedAccessories":
            return t`Included Accessories`;
        case "innovation":
            return t`Innovation`;
        case "netWeight":
            return t`Net Weight (Approx.)`;
        case "optionalAccessories":
            return t`Optional Accessories`;
        case "warranty":
            return t`Warranty`;
        case "performance":
            return t`Performance`;
        case "capacityAndStorage":
            return t`Capacity and Storage`;
    }
    assertNever(title);
};

export const SpecificationDetails = ({ value, umrp }: Props) => {
    if (!value) return;

    return (
        <dl className={styles.dl}>
            {value.map(({ section, items }, sIndex) => (
                <Fragment key={`pd-section-${sIndex}`}>
                    {section && items ? (
                        <div
                            className={concatClassNames([
                                styles.definitionGroup,
                                section === "innovation" ? styles.featured : "",
                            ])}
                        >
                            <dt className={styles.dt}>
                                {mappedSectionTitle(section)}
                            </dt>
                            <div className={styles.ddGroup}>
                                {items.map((item, index) => (
                                    <Fragment
                                        key={`pd-section-${sIndex}-${index}`}
                                    >
                                        {section === "innovation" && (
                                            <Svg
                                                name="check"
                                                className={styles.check}
                                                aria-hidden="true"
                                            />
                                        )}
                                        <RichText
                                            value={item}
                                            tagName="dd"
                                            className={styles.dd}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </Fragment>
            ))}
            {umrp && (
                <div className={styles.definitionGroup}>
                    <dt className={styles.dt}>{t`Price`}</dt>
                    <div className={styles.ddGroup}>
                        <RichText
                            value={`<p>${umrp.formatted}</p>`}
                            tagName="dd"
                            className={styles.dd}
                        />
                    </div>
                </div>
            )}
        </dl>
    );
};
