import React from "react";

import { MediaChooserBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock as LabUIMediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import { VideoPlayerControls } from "../VideoPlayerControls";

interface Props {
    value: Value;
    type?: null | ("background" | "foreground");
    wrapperAttrs?: React.HTMLAttributes<HTMLElement>;
    mediaAttrs?: React.HTMLAttributes<HTMLElement>;
}

export const MediaChooserBlock = ({
    value,
    type,
    wrapperAttrs,
    mediaAttrs,
}: Props) => {
    if (!value || value.length === 0) return <></>;
    const media = value[0];

    if (media.type === "video")
        return (
            <VideoPlayerControls
                value={media.value}
                type={type}
                wrapperAttrs={wrapperAttrs}
                attrs={mediaAttrs}
            />
        );

    if (media.type === "image")
        return (
            <ImageChooserBlock
                value={media.value}
                attrs={wrapperAttrs}
                imageAttrs={mediaAttrs}
            />
        );

    return <LabUIMediaChooserBlock value={value} attrs={wrapperAttrs} />;
};
