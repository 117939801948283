import React from "react";
import { t } from "ttag";

import { ContactSubscribeBlock as Value } from "@reactivated";

import { StayInformedForm } from "../CRMForm/StayInformedForm";
import { Clickable } from "../Clickables";
import { RichText } from "../RichText";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const ContactSubscribeBlock = ({ value }: { value: Value }) => {
    const showContactColumn =
        value.contactText || value.contactLinkText || value.phone_e164;
    return (
        <section
            className={
                showContactColumn
                    ? styles.twoChildrenRoot
                    : styles.singleChildRoot
            }
        >
            <div className={styles.inner}>
                <div className={styles.formColumn}>
                    {value.formText ? (
                        <RichText value={value.formText} />
                    ) : (
                        <h2 className={styles.title}>{t`Subscribe`}</h2>
                    )}
                    <StayInformedForm
                        legalText={value.legalText}
                        layout="complementary"
                    />
                </div>
                {showContactColumn && (
                    <div className={styles.contact}>
                        {value.contactText && (
                            <RichText value={value.contactText} />
                        )}
                        {value.contactLinkText && value.contactLink && (
                            <div className={styles.contactItem}>
                                <Svg
                                    name="mail"
                                    className={styles.svg}
                                    aria-hidden="true"
                                />
                                <Clickable
                                    href={value.contactLink}
                                    className={styles.textLink}
                                >
                                    {value.contactLinkText}
                                </Clickable>
                            </div>
                        )}
                        {value.phone_national &&
                            value.phone_e164 &&
                            value.locale && (
                                <div className={styles.contactItem}>
                                    <Svg
                                        name="phone"
                                        className={styles.svg}
                                        aria-hidden="true"
                                    />
                                    <Clickable
                                        href={`tel:${value.phone_e164}`}
                                        className={styles.textLink}
                                    >
                                        {value.phone_national}
                                    </Clickable>
                                </div>
                            )}
                    </div>
                )}
            </div>
        </section>
    );
};
