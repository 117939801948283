import React from "react";

import { FullWidthImageBlock as Value } from "@reactivated";

import { HeroLayoutSettings } from "@thelabnyc/thelabui/src/components/Flexihero/HeroLayout.interfaces";
import { HeroSet } from "@thelabnyc/thelabui/src/components/Flexihero/HeroSet";

import { MediaChooserBlock } from "../MediaChooserBlock";

export const FullWidthImageBlock = ({ value }: { value: Value }) => {
    const media = value.media ? (
        <MediaChooserBlock value={value.media} type={value.video_type} />
    ) : null;

    const settings: HeroLayoutSettings = {
        heroDirection: "row",
        heroPlacement: value.container_width ?? "container",
        mediaPlacement: "background",
        mediaAspectRatio: "16:9",
        contentAlignPrimary: "middle",
        contentAlignSecondary: "middle",
        textAlignment: "center",
        contentWidth: 6,
        maxHeight: 612,
    };

    return (
        <HeroSet
            layouts={{
                desktop: { content: null, media, settings },
                mobile: {
                    content: null,
                    media,
                    settings: {
                        ...settings,
                        mediaAspectRatio: "0.8575:1",
                        maxHeight: 400,
                    },
                },
            }}
        />
    );
};
