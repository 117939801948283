import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Favorite } from "../../favorites";
import { Share } from "../Share";
import { FavoriteControl } from "./FavoriteControl";

import styles from "./Actions.module.scss";

interface Props {
    product: Favorite;
    hideAboveTablet?: boolean;
}

export const Actions = ({ product, hideAboveTablet = false }: Props) => (
    <div
        className={concatClassNames([
            styles.actions,
            hideAboveTablet ? styles.hideAboveTablet : undefined,
        ])}
    >
        <FavoriteControl product={product} />
        <Share title={product.name} />
    </div>
);
