import React from "react";
import { Dialog, DialogTrigger, Modal } from "react-aria-components";
import { t } from "ttag";

import {
    DocumentChooserBlockOrNull,
    RichTextBlockOrNull,
    CenteredHeroWithModalBlock as Value,
} from "@reactivated";

import { CenteredHero } from ".";
import { DesignGuideDownloadForm } from "../CRMForm/DesignGuideDownload";
import { ButtonPrimary, Clickable } from "../Clickables";
import { MediaChooserBlock } from "../MediaChooserBlock";
import { CloseButton } from "../ModalVideoHero/CloseButton";
import { RichText } from "../RichText";

import styles from "./centered-hero-modal.module.scss";

const Content = ({
    content,
    formContent,
    documentLink,
    successContent,
}: {
    content: string;
    formContent: RichTextBlockOrNull;
    documentLink: DocumentChooserBlockOrNull;
    successContent: RichTextBlockOrNull;
}) => (
    <>
        <RichText value={content} />
        <DialogTrigger>
            <ButtonPrimary className={styles.trigger}>
                {t`Get the Design Guide`}
            </ButtonPrimary>
            <Modal className={styles.modal}>
                <Dialog className={styles.dialog}>
                    {({ close }) => (
                        <>
                            <Clickable
                                className={styles.overlay}
                                onPress={close}
                                aria-hidden="true"
                                tabIndex={-1}
                            />
                            <CloseButton
                                closeText={t`Close Video`}
                                onPress={close}
                            />
                            <div className={styles.content}>
                                {formContent ? (
                                    <RichText value={formContent} />
                                ) : null}

                                <DesignGuideDownloadForm
                                    layout="complementary"
                                    documentLink={documentLink}
                                    successContent={successContent}
                                />
                            </div>
                        </>
                    )}
                </Dialog>
            </Modal>
        </DialogTrigger>
    </>
);

export const CenteredHeroWithModal = ({ value }: { value: Value }) => (
    <CenteredHero
        content={
            value.content ? (
                <Content
                    content={value.content}
                    formContent={value.form_content}
                    documentLink={value.design_guide}
                    successContent={value.success_content}
                />
            ) : null
        }
        media={
            value.media && value.media.length > 0 ? (
                <MediaChooserBlock value={value.media} />
            ) : null
        }
        isPromo={false}
        height={value.height}
    />
);
