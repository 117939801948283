import React, { useContext } from "react";
import { t } from "ttag";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Favorite, FavoritesContext, isFavorite } from "../../favorites";
import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./FavoriteControl.module.scss";

export const FavoriteControl = (props: { product: Favorite }) => {
    const favs = useContext(FavoritesContext);
    const isFav = isFavorite(favs.items, props.product);

    const onToggle = () => {
        if (isFav) {
            favs.remove(props.product);
        } else {
            favs.add(props.product);
        }
    };

    return (
        <Clickable
            className={concatClassNames([
                styles.button,
                isFav ? styles.isFavorite : null,
            ])}
            onPress={onToggle}
        >
            <Svg
                name={isFav ? "heart-filled" : "heart"}
                visuallyHiddenText={
                    isFav ? t`Remove from favorites` : t`Add to favorites`
                }
            />
        </Clickable>
    );
};
