import React from "react";

import { ScrollNavMenuBlock as BaseScrollNavMenuBlock } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";

import { ScrollNavMenuContainer } from "./ScrollNavMenuContainer";

import styles from "./index.module.scss";

export { ScrollNavMenuItemBlock } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";

interface Props
    extends React.ComponentPropsWithoutRef<typeof BaseScrollNavMenuBlock> {
    sku: string;
    title: string;
}

/**
 * Renders a sticky "table of contents" nav bar for navigating between sections
 * in the page. Items in the ToC are derived from whatever ScrollNavMenuItemBlock
 * components are also rendered on the page.
 */
export const ScrollNavMenuBlock = ({ title, sku, ...props }: Props) => (
    <BaseScrollNavMenuBlock
        {...props}
        classNames={{
            menuItem: styles.menuItem,
            menuItemSelected: styles.menuItemSelected,
        }}
        dependencies={{
            ScrollNavMenuContainer: (args) => (
                <ScrollNavMenuContainer {...args} title={title} sku={sku} />
            ),
        }}
    />
);
